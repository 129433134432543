
import {
  IonContent,
  IonItem,
  IonList,
  IonMenu,
  IonHeader,
  IonToolbar,
  menuController
} from "@ionic/vue";
import { menuOutline } from "ionicons/icons";
import { defineComponent } from "vue";

export default defineComponent({
  name: "MainMenu",
  components: {
    IonContent,
    IonItem,
    IonList,
    IonMenu,
    IonHeader,
    IonToolbar,
  },
  setup() {
    return { menuOutline }
  },
  computed: {
    getUser() {
      return this.$store.state.myUser
    }
  },
  methods: {
    goNClose(route: string) {
      menuController.close('first');
      this.$router.push(route)
    },
    logout() {
      this.$store.dispatch('logout')
      menuController.close('first');
      this.$router.push('/')
    },
  }
});
