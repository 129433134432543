import { FirePermit } from '@/models/permit';
import { Parser, AsyncParser } from 'json2csv';
import { asyncForEach } from './form.utils';

// TODO, switch this to the async parser, or maybe do this server side?
// TODO double check we have the correct fields & order
const permitOptions = {
    fields: ['permitId', 'additionalLocationInfo', 'address', 'city', 'existingSmallGardenSpot', 'firstName', 'lastName', 'handPiledVegetation', 'lastName', 'email', 'permitType', 'phoneNp', 'state', 'acceptedTerms', 'submittedAt', 'zip'],
    flatten: true,
};

const transformOpts = { highWaterMark: 8192 };

const asyncParser = new AsyncParser(permitOptions, transformOpts);
const parser = new Parser(permitOptions);

export async function downloadCSV(array: FirePermit[]) {
    return parser.parse(array);
}

export async function downloadCSVAsync(array: FirePermit[]) {
    let csv = '';
    asyncParser.processor
        .on('data', chunk => (csv += chunk.toString()))
        // .on('end', () => console.log(csv))
        .on('error', err => console.error(err));

    await asyncForEach(array, async (permit: FirePermit) => {
        asyncParser.input.push(JSON.stringify(permit)); // This data might come from an HTTP request, etc.
    });

    await asyncParser.input.push(null); // Sending `null` to a stream signal that no more data is expected and ends it.
    return csv;
}
