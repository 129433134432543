<template>
  <ion-page>
    <ion-content class="ion-padding-bottom" color="light">
      <logo-header />
      <ion-row class="ion-padding-top">
        <ion-col size-md="2" size="auto"> </ion-col>
        <ion-col
          size-md="8"
          class="ion-text-start ion-padding-horizontal ion-padding-bottom"
        >
          <h2 class="ion-no-margin">Review Permit Application</h2>
        </ion-col>
        <ion-col size-md="2" size="auto"> </ion-col>
      </ion-row>
      <permit-heading-info :disabled="loading" />
      <info-review-block :disabled="loading" />
      <ordinance-form v-show="!loading" />

      <ion-grid>
        <ion-row v-show="loading" style="min-height: 468px">
          <ion-col size-md="2"> </ion-col>
          <ion-col size-md="8" class="ion-text-center">
            <h1>
              {{
                errorTxt && errorTxt.length
                  ? errorTxt
                  : "Submitting permit application, please wait."
              }}
            </h1>
          </ion-col>
          <ion-col size-md="2"> </ion-col>
        </ion-row>
        <ion-row v-show="!loading">
          <ion-col size="4"> </ion-col>
          <ion-col size-md="4" class="flex">
            <div class="auto">
            <div id="recaptcha-container"></div>
            </div>
          </ion-col>
          <ion-col size="4"> </ion-col>
        </ion-row>
        <ion-row class="ion-padding-top">
          <ion-col size-md="2"> </ion-col>
          <ion-col size-md="8" class="flex">
            <ion-button
              class="auto"
              color="primary"
              @click="checkForm"
              :disabled="loading"
              >{{
                loading
                  ? $t("review.submitting")
                  : $t("review.submitApplicationButton")
              }}</ion-button
            >
          </ion-col>
          <ion-col size-md="4"> </ion-col>
        </ion-row>
        <back-button-link route="/check" />
        <ion-row class="ion-padding"> </ion-row>
      </ion-grid>
    </ion-content>
    <ion-footer>
      <ion-progress-bar v-if="loading" type="indeterminate"></ion-progress-bar>
      <gbp-footer />
    </ion-footer>
  </ion-page>
</template>

<script lang="ts">
import {
  IonButton,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonPage,
  IonProgressBar,
  IonRow,
} from "@ionic/vue";
import { defineComponent, reactive, ref } from "vue";
import { useSnackbarPlugin } from "snackbar-vue";
import InfoReviewBlock from "@/components/permit/InfoReviewBlock.vue";
import LogoHeader from "../components/LogoHeader.vue";
import OrdinanceForm from "../components/permit/OrdinanceForm.vue";
import PermitHeadingInfo from "../components/permit/PermitHeadingInfo.vue";
import BackButtonLink from "../components/permit/BackButtonLink.vue";
import GbpFooter from "@/components/Footer.vue";

// Firebase App (the core Firebase SDK) is always required and must be listed first
import firebase from "firebase/app";
// Add the Firebase products that you want to use
import "firebase/auth";
import { FirePermit } from "@/models/permit";

export default defineComponent({
  name: "ReviewPage",
  components: {
    PermitHeadingInfo,
    IonButton,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonPage,
    IonProgressBar,
    IonRow,
    LogoHeader,
    OrdinanceForm,
    InfoReviewBlock,
    BackButtonLink,
    GbpFooter
  },
  setup() {
    const loading = ref(false);
    const snack = useSnackbarPlugin();
    const errorTxt = ref("");
    const formData = reactive(new FirePermit());
    return { loading, errorTxt, formData, snack };
  },
  async mounted() {
    await this.$store.dispatch("initFromStorage"); // TODO dont you shouldnt be here if not.
    // TODO THIS didn't work
    // setTimeout(() => {
    //   console.log('checking apply ' + this.canApply)
    //   if (this.canApply) {
    //     this.$router.push('/check')
    //     return 
    //   }
    // }, 500)

    if (this.getSavedFormData) {
      this.formData = Object.assign(this.formData, this.getSavedFormData);
    }
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "normal",
        callback: (response: any) => {
          // TODO
          // reCAPTCHA solved, token saved, but I think this is done automatically by firestore
          // and included in request. This is redundant , and just for me.
          this.$store.dispatch("setFormDataNoSave", { cToken: response });
        },
        "expired-callback": () => {
          // Response expired. Ask user to solve reCAPTCHA again.
          this.$store.dispatch("setFormDataNoSave", { cToken: undefined });
        },
      }
    );
    // apparently you need this. to make it show prior to submit
    window.recaptchaVerifier.render().then((widgetId: any) => {
      window.recaptchaVerifier.reset(widgetId);
    });
  },
  computed: {
    getSavedFormData(): any {
      return this.$store.state.formData;
    },
    canApply(): any {
      return this.$store.state.canApply;
    },
  },
  methods: {
    async checkForm() {
      if (!this.canApply) {
        this.snack.show({
          position: "bottom",
          text: this.errorTxt ? this.errorTxt : "You may already have an existing permit",
          time: 2500,
          close: true,
        });
        return 
      }

      this.loading = true;
      // is form cool?

      if (!this.getSavedFormData.acceptedTerms) {
        setTimeout(() => {
          this.errorTxt = "Please accept the terms and conditions.";
        }, 500);
        setTimeout(() => {
          this.errorTxt = "";
          this.loading = false;
        }, 1500);
        return;
      }
      // if (!this.getSavedFormData.cToken) {
      //   setTimeout(() => {
      //     this.errorTxt = "Please check the CAPTCHA.";
      //   }, 500);
      //   setTimeout(() => {
      //     this.errorTxt = "";
      //     this.loading = false;
      //   }, 1500);
      //   return;
      // }

      const errorMsg = await this.$store.dispatch(
        "submitPermitApplication",
        this.getSavedFormData
      );
      if (errorMsg && errorMsg.length) {
        this.errorTxt = errorMsg;
        console.log(errorMsg)
        setTimeout(() => {
          this.errorTxt = "";
          this.loading = false;
        }, 2500);
      } else {
        this.loading = false;
        this.$router.push("/complete");
      }

      // setTimeout(() => {
      //   this.errorTxt =
      //     "Application failed. Please try again later or call the toll free number.";
      // }, 2500);
      // setTimeout(() => {
      //   this.errorTxt = "";
      //   this.loading = false;
      // }, 5000);

      //
    },
  },
});
</script>