<template>
  <div class="w100 accent ion-padding ion-hide-md-down">
    <ion-grid>
      <ion-row>
        <ion-col size="4">
          <div class="text--white">{{ $t("footer.copyright") }}</div>
        </ion-col>
        <ion-col class="w100"> </ion-col>
        <ion-col  size="auto">
          <div class="text--white ion-text-end nowrap pointer">
            <a :href="termsLink" target="_blank">{{ $t("footer.terms") }}</a>
          </div>
        </ion-col>
        <ion-col  size="auto" >
          <div class="text--white ion-text-end nowrap">
           <a :href="privacyLink" target="_blank">{{ $t("footer.privacy") }}</a>
          </div>
        </ion-col>
        <ion-col  size="auto">
          <div class="text--white ion-text-end nowrap">
               <a :href="privacyLink" target="_blank">{{ $t("footer.contactUs") }}</a>
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { IonGrid, IonRow, IonCol } from "@ionic/vue";
import { PRIVACY_POLICY_LINK, TERMS_LINK, CONTACT_LINK } from "@/constants";

export default defineComponent({
  name: "GbpFooter",
  components: {
    IonGrid,
    IonRow,
    IonCol,
  },
  computed: {
    termsLink() {
      return TERMS_LINK;
    },
    privacyLink() {
      return PRIVACY_POLICY_LINK;
    },
    contactLink() {
      return CONTACT_LINK;
    }

  }
});
</script>
