<template>
  <form
    @submit.prevent="
      onSubmit(vales);
      showErrors = true;
    "
  >
    <ion-grid>
      <ion-row>
        <ion-col size-lg="2" size="auto"> </ion-col>
        <ion-col size-lg="8" class="ion-text-start ion-padding-horizontal">
          <h3 class="ion-no-margin">
            {{ $t("permit.generalHeading") }}
          </h3>
        </ion-col>
        <ion-col size-lg="2" size="auto"> </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size-lg="2"> </ion-col>
        <ion-col size-lg="8" size="12">
          <ion-list 
            class="ion-no-padding"
            style="background: transparent"
>
            <ion-item color="light">
              <ion-label position="floating">{{
                $t("permit.firstName")
              }}</ion-label>
              <ion-input
                debounce="500"
                @ionChange="firstName = firstName.trim().toUpperCase(); saveChangesLocally('firstName', firstName)"
                autocomplete
                v-model="firstName"
                name="firstName"
                type="text"
                enterkeyhint="next"
                inputmode="text"
                maxlength="50"
              ></ion-input>
            </ion-item>
            <input-error v-if="firstNameError" :show="showErrors" :msg="firstNameError" />
            <ion-item color="light">
              <ion-label position="floating">{{
                $t("permit.lastName")
              }}</ion-label>
              <ion-input
                debounce="500"
                @ionChange="lastName = lastName.trim().toUpperCase(); saveChangesLocally('lastName', lastName)"
                autocomplete
                v-model="lastName"
                name="lastName"
                type="text"
                enterkeyhint="next"
                inputmode="text"
                maxlength="50"
              ></ion-input>
            </ion-item>
            <input-error  v-if="lastNameError"  :show="showErrors" :msg="lastNameError" />
            <ion-item color="light">
              <ion-label position="floating">{{
                $t("permit.address")
              }}</ion-label>
              <ion-input
                debounce="500"
                @ionChange="address = address.toUpperCase(); saveChangesLocally('address', address)"
                autocomplete
                v-model="address"
                name="address"
                type="text"
                enterkeyhint="next"
                inputmode="text"
                maxlength="500"
              ></ion-input>
            </ion-item>
            <input-error v-if="addressError"  :show="showErrors" :msg="addressError" />
            <ion-item color="light">
              <ion-label position="floating">{{ $t("permit.city") }}</ion-label>
              <ion-input
                debounce="500"
                @ionChange="city = city.trim().toUpperCase(); saveChangesLocally('city', city)"
                autocomplete
                v-model="city"
                name="city"
                type="text"
                enterkeyhint="next"
                inputmode="text"
                maxlength="200"
              ></ion-input>
            </ion-item>
            <input-error v-if="cityError" :show="showErrors" :msg="cityError" />
            <ion-item color="light">
              <ion-label position="floating">{{ $t("permit.zip") }}</ion-label>
              <ion-input
                autocomplete
                debounce="500"
                @ionChange="formatZip($event); saveChangesLocally('zip', zip)"
                type="number"
                inputmode="numeric"
                v-model="zip"
                name="zip"
                enterkeyhint="next"
                min="30000"
                max="30097"
              ></ion-input>
            </ion-item>
            <input-error v-if="zipError" :show="showErrors" :msg="zipError" />
            <ion-item color="light">
              <ion-label position="floating">{{
                $t("permit.phoneNo")
              }}</ion-label>
              <ion-input
                autocomplete
                debounce="500"
                @ionChange="formatPhone($event); saveChangesLocally('phoneNo', phoneNo)"
                type="tel"
                inputmode="tel"
                name="phoneNo"
                enterkeyhint="next"
                v-model="phoneNo"
              ></ion-input>
            </ion-item>
            <input-error v-if="phoneNoError"  :show="showErrors" :msg="phoneNoError" />
            <ion-item color="light">
              <ion-label position="floating">{{
                $t("permit.email")
              }}</ion-label>
              <ion-input
                autocomplete
                debounce="500"
                @ionChange="email.trim().toLowerCase(); saveChangesLocally('email', email)"
                type="email"
                inputmode="email"
                enterkeyhint="done"
                name="email"
                v-model="email"
              ></ion-input>
            </ion-item>
            <input-error v-if="emailError" :show="showErrors" :msg="emailError" />
            <ion-item color="light">
              <ion-label position="stacked">{{
                $t("permit.locationInfo")
              }}</ion-label>
              <ion-textarea
                debounce="500"
                maxlength="500"
                @ionChange="
                  saveChangesLocally(
                    'additionalLocationInfo',
                    additionalLocationInfo
                  )
                "
                v-model="additionalLocationInfo"
                name="additionalLocationInfo"
                rows="2"
              ></ion-textarea>
            </ion-item>
            <input-error
              v-if="additionalLocationInfoError"
              :show="showErrors"
              :msg="additionalLocationInfoError"
            />
          </ion-list>
        </ion-col>
        <ion-col size-lg="2"> </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size-lg="2" size="auto"> </ion-col>
        <ion-col size-lg="8" class="ion-text-start ion-padding">
          <h2 class="ion-no-margin">
            {{ $t("permit.whatYouBurning") }}
          </h2>
        </ion-col>
        <ion-col size-lg="2" size="auto"> </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size-lg="2" size="auto"> </ion-col>
        <ion-col size-lg="8" class="ion-text-start ion-padding">
          <ion-checkbox
            color="primary"
            debounce="500"
            @ionChange="
              saveChangesLocally('handPiledVegetation', handPiledVegetation)
            "
            v-model="handPiledVegetation"
            name="handpiledvegetation"
          ></ion-checkbox>
          <ion-label class="ion-padding-start"
            >{{ $t("permit.handPiled") }}
          </ion-label>
        </ion-col>
        <ion-col size-lg="2" size="auto"> </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size-lg="2" size="auto"> </ion-col>
        <ion-col size-lg="8" class="ion-text-start ion-padding">
          <ion-checkbox
            color="primary"
            debounce="500"
            @ionChange="
              saveChangesLocally(
                'existingSmallGardenSpot',
                existingSmallGardenSpot
              )
            "
            v-model="existingSmallGardenSpot"
            name="existingsmallgardenspot"
          ></ion-checkbox>
          <ion-label class="ion-padding-start">{{
            $t("permit.existingGarden")
          }}</ion-label>
        </ion-col>
        <ion-col size-lg="2" size="auto"> </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size-lg="2" size="auto"> </ion-col>
        <ion-col size-lg="8" class="ion-text-center ion-padding">
          <h4 class="ion-no-margin ion-text-uppercase">
            {{ $t("permit.burnNotice") }}
          </h4>
        </ion-col>
        <ion-col size-lg="2" size="auto"> </ion-col>
      </ion-row>
      <ion-row class="flex ion-padding0top">
        <div class="auto">
          <ion-text v-if="errorText" color="danger">
            {{ $t("permit.checkSubmission") }}
          </ion-text>
        </div>
      </ion-row>
      <ion-row class="ion-padding-top">
        <ion-col size="2"> </ion-col>
        <ion-col size-lg="8">
          <ion-button
            color="primary"
            expand="full"
            :disabled="loading || errorText || (!existingSmallGardenSpot && !handPiledVegetation)"
            type="submit"
            >{{ $t("apply.continueButton") }}</ion-button
          >
        </ion-col>
        <ion-col size="2"> </ion-col>
      </ion-row>
      <back-button-link route="/" />
      <ion-row class="ion-padding"> </ion-row>
    </ion-grid>
  </form>
</template>
<script lang='ts'>
import {
  IonButton,
  IonCol,
  IonGrid,
  IonItem,
  IonInput,
  IonTextarea,
  IonLabel,
  IonList,
  IonRow,
  IonCheckbox,
  IonText,
} from "@ionic/vue";

import { defineComponent, ref } from "vue";

import BackButtonLink from "./BackButtonLink.vue";
import InputError from "./InputError.vue";
import { useForm, useField } from "vee-validate";
import {
  validateEmail,
  isRequired,
  validatePhone,
  validateZipCode,
  max500Chars,
  formatPhoneNumber,
} from "@/utils/form.validators";

export default defineComponent({
  name: "FirePermitForm",
  props: {
    submitHandler: null, // the parent is in charge.
    loading: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    IonButton,
    IonCheckbox,
    IonCol,
    IonGrid,
    IonItem,
    IonInput,
    IonText,
    IonTextarea,
    IonLabel,
    IonList,
    IonRow,
    BackButtonLink,
    InputError,
  },
  setup(props) {
    const showErrors = ref(false);
    const { handleSubmit } = useForm();
    const onSubmit = handleSubmit(props.submitHandler);

    const { value: firstName, errorMessage: firstNameError } = useField(
      "firstName",
      isRequired
    );
    const { value: lastName, errorMessage: lastNameError } = useField(
      "lastName",
      isRequired
    );
    const { value: address, errorMessage: addressError } = useField(
      "address",
      isRequired
    );
    const { value: city, errorMessage: cityError } = useField(
      "city",
      isRequired
    );
    const { value: zip, errorMessage: zipError } = useField(
      "zip",
      validateZipCode
    );
    const { value: phoneNo, errorMessage: phoneNoError } = useField(
      "phoneNo",
      validatePhone
    );
    const { value: email, errorMessage: emailError } = useField(
      "email",
      validateEmail
    );
    const {
      value: additionalLocationInfo,
      errorMessage: additionalLocationInfoError,
    } = useField("additionalLocationInfo", max500Chars);
    const {
      value: handPiledVegetation,
      errorMessage: handPiledVegetationError,
    } = useField("handPiledVegetation");
    const {
      value: existingSmallGardenSpot,
      errorMessage: existingSmallGardenSpotError,
    } = useField("existingSmallGardenSpot");
    return {
      firstName,
      lastName,
      address,
      city,
      zip,
      phoneNo,
      email,
      additionalLocationInfo,
      handPiledVegetation,
      existingSmallGardenSpot,
      firstNameError,
      lastNameError,
      addressError,
      cityError,
      zipError,
      phoneNoError,
      emailError,
      additionalLocationInfoError,
      handPiledVegetationError,
      existingSmallGardenSpotError,
      onSubmit,
      showErrors,
    };
  },
  mounted() {
    // we need to wait for the store to get crap outta storage.
    setTimeout(() => {
      if (this.getSavedFormData) {
        this.firstName = this.getSavedFormData.firstName;
        this.lastName = this.getSavedFormData.lastName;
        this.address = this.getSavedFormData.address;
        this.city = this.getSavedFormData.city;
        this.zip = this.getSavedFormData.zip;
        this.phoneNo = this.getSavedFormData.phoneNo;
        this.email = this.getSavedFormData.email;
        this.additionalLocationInfo =
          this.getSavedFormData.additionalLocationInfo;
        this.handPiledVegetation = this.getSavedFormData.handPiledVegetation;
        this.existingSmallGardenSpot =
          this.getSavedFormData.existingSmallGardenSpot;
      }
    }, 100);
  },
  computed: {
    getSavedFormData(): any {
      return this.$store.state.formData;
    },
    errorText(): boolean {
      if (
        this.showErrors &&
        (this.firstNameError ||
          this.lastNameError ||
          this.addressError ||
          this.cityError ||
          this.zipError ||
          this.phoneNoError ||
          this.emailError ||
          this.additionalLocationInfoError ||
          this.handPiledVegetationError ||
          this.existingSmallGardenSpotError)
      ) {
        return true;
      }
      return false;
    },
  },
  methods: {
    saveChangesLocally(key: string, value: any) {
      this.$store.dispatch("setFormData", { [key]: value });
    },
    formatPhone(fon: any) {
      this.phoneNo = formatPhoneNumber(fon.detail.value);
    },
    formatZip(zip: any) {
      this.zip = this.zipHip(zip.detail.value).slice(0,5)
    },
    zipHip(zip: string) {
      return ("" + zip).replace(/\D/g, "");
    }
  },
});
</script>
