<template>
  <ion-grid>
    <ion-row class="ion-padding">
      <ion-col size-md="2" size="auto"> </ion-col>
      <ion-col
        size-md="8"
        class="ion-justify-content-between ion-align-items-center"
      >
        <ion-grid>
          <ion-row>
            <ion-col size-md="12" style="min-height: 148px">
              <div v-show="!selectedCounty" class="flex" style="flex-direction: column">
                <div>
                  <ion-text class="ion-text-center">
                    <h3>{{$t("apply.first")}}.</h3>
                  </ion-text>
                </div>
              </div>
              <div v-show="loading" class="flex" style="flex-direction: column">
                <div>
                  <ion-text class="ion-text-center">
                    <h3>{{ $t("apply.checkIfPermitsAvailable") }}</h3>
                  </ion-text>
                </div>
                <div class="flex ion-padding-top">
                  <ion-spinner
                    style="transform: scale(2)"
                    class="auto"
                    name="crescent"
                  ></ion-spinner>
                </div>
              </div>
              <div v-show="!loading && init && excludedDates && selectedCounty" class="flex" style="flex-direction: column">
                <div>
                  <ion-text
                    class="ion-text-center"
                    :color="areAvailable ? 'secondary' : 'danger'"
                  >
                    <h3>
                      <strong>{{
                        areAvailable
                          ? $t("apply.permitsAreAvailable")
                          : $t("apply.permitsAreNotAvailable")
                      }}
                      {{selectedCounty ? (selectedCounty.name + ' on ') : ''}}
                      {{today}}</strong>
                    </h3>
                    <h3 v-if="areAvailable">
                      <strong>No burn bans in effect.</strong>
                    </h3>
                  </ion-text>
                </div>
                <div v-if="reason">
                  <ion-text
                    class="ion-text-center"
                    color="danger"
                  >
                    <h3>
                      <strong>{{ reason }}</strong>
                    </h3>
                  </ion-text>
                </div>
                <div class="flex ion-padding-top" style="min-height: 70px">
                  <ion-icon
                    :class="
                      areAvailable ? 'text--success auto' : 'text--danger auto'
                    "
                    :icon="
                      areAvailable ? checkmarkCircleOutline : closeCircleOutline
                    "
                    style="font-size: 70px"
                  ></ion-icon>
                </div>
              </div>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-col>
      <ion-col size-md="2" size="auto"> </ion-col>
    </ion-row>
  </ion-grid>
</template>
<script lang="ts">
import { IonCol, IonGrid, IonIcon, IonRow, IonSpinner, IonText } from "@ionic/vue";

import { defineComponent, ref } from "vue";
import { checkmarkCircleOutline, closeCircleOutline } from "ionicons/icons";
import { dateMixin } from "../../utils/date.format";

export default defineComponent({
  name: "ArePermitsAvailable",
  mixins: [dateMixin],
  components: {
    IonCol,
    IonGrid,
    IonIcon,
    IonRow,
    IonSpinner,
    IonText,
  },
  setup() {
    const loading = ref(false);
    const init = ref(false);
    return {
      loading,
      init,
      closeCircleOutline,
      checkmarkCircleOutline,
    };
  },
  mounted() {
    setTimeout(() => {
      this.init = true
    }, 300)
  },
  computed: {
    selectedCounty(): any {
      return this.$store.state.selectedCounty;
    },
    excludedDates(): any[] {
      return this.$store.state.selectedCountyExcludedDates;
    },
    areAvailable(): boolean {
      return !this.reason;
    },
    reason(): string {
      let reason = '';
        if (this.excludedDates && this.excludedDates.length) {
          this.excludedDates.forEach(
            (d: { date: { seconds: number; nanoseconds: number }; reason: string }) => {
              if (this.isSameAsToday(d.date.seconds)) {
                reason = d.reason;
              }
            }
          );
      }
      if (this.selectedCounty && !this.selectedCounty.enabled) {
        reason = 'Permits are not available for this county yet.'
      }
      return reason;
    },
    today(): any {
      return this.todaysDate()
    }
  },
  // watch: {
  //   excludedDates() {
  //     // TODO improve jank.
  //     this.loading = true;
  //     setTimeout(() => {
  //       this.loading = false;
  //     }, 1000);
  //   },
  // },
});
</script>
