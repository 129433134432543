
import { defineComponent } from "vue";
import { Calendar } from "v-calendar";
import CalendarDayPopover from "./CalendarDayPopover.vue";
import { popoverController } from "@ionic/vue";
import { ExcludedDate } from "@/models/excluded";
import { closeCircleOutline } from "ionicons/icons";
import { IonIcon } from "@ionic/vue";
export default defineComponent({
  name: "BurnCalendar",
  components: {
    Calendar,
    IonIcon
  },
  emits: ["remove"],
  setup() {
    return { closeCircleOutline };
  },
  computed: {
    excludedDates(): any[] {
      return this.$store.state.selectedCountyExcludedDates;
    },
    toAttributes(): any[] {
      if (this.excludedDates && this.excludedDates.length) {
        const o: any[] = [];
        this.excludedDates.forEach((exDate: ExcludedDate) => {
          o.push({
            key: 2,
            customData: {
              exDate: exDate,
            },
            dates: new Date((exDate.date!.seconds ?? 0) * 1000),
          });
        });
        return o;
      }
      return [];
    },
    getCalendarData(): any {
      return {
        masks: {
          weekdays: "WWW",
        },
        attributes: this.toAttributes,
      };
    },
  },
  methods: {
    // this date: someCool type from
    async openPop(date: any, exDate: ExcludedDate, $event: Event) {
      const popover = await popoverController.create({
        component: CalendarDayPopover,
        componentProps: {
          event: $event,
          dateString: date.ariaLabel,
          exDate: exDate,
        },
      });
      await popover.present();

      const { data } = await popover.onDidDismiss();
      if (data) {
        this.$emit("remove", data);
      }
    },
    truncCalendarText(text: string) {
      if (!text) return "";
      const a = text.length > 25 ? "..." : "";
      return text.slice(0, 25) + a;
    },
  },
});
