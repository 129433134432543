
import {
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonRow,
  modalController,
} from "@ionic/vue";
import { caretDownOutline } from "ionicons/icons";

import Modal from "./modal.vue";
import Modal2 from "./modal2.vue";
import { defineComponent, reactive } from "vue";
import { FirePermit } from "../../models/permit";

export default defineComponent({
  name: "ApplicationForm",
  components: {
    IonCol,
    IonGrid,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonRow,
  },
  setup() {
    const formData = reactive(new FirePermit());
    return {
      caretDownOutline,
      formData,
    };
  },
  mounted() {
    setTimeout(() => {
      if (this.getSavedFormData) {
        this.formData = reactive(
          Object.assign(this.formData, this.getSavedFormData)
        );
      }
      if (!this.formData.permitType) {
        this.formData.permitType = "Daily";
        this.saveFormData()
      }
    }, 100);
  },
  computed: {
    selectedCounty(): any {
      return this.$store.state.selectedCounty;
    },
    getSavedFormData(): any {
      return this.$store.state.formData;
    },
  },
  methods: {
    async openPermitTypeModal() {
      const modal = await modalController.create({
        component: Modal2,
      });
      await modal.present();

      const { data } = await modal.onDidDismiss();
      if (data) {
        this.formData.permitType = data;
        this.$store.dispatch("setFormData", {
          permitType: this.formData.permitType,
        });
      }
    },
    async openModal() {
      const modal = await modalController.create({
        component: Modal,
      });
      modal.present();
      const result = await modal.onDidDismiss();
      if (result && result.data) {
        this.$store.dispatch("saveFromListSelectedCounty", result.data);
      }
    },
    saveFormData() {
      this.$store.dispatch("setFormData", {
        permitType: this.formData.permitType,
      });
    },
  },
});
