
import { IonGrid, IonRow, IonCol, modalController } from "@ionic/vue";
import { defineComponent } from "vue";
import Modal from "./modal.vue";
import Modal2 from "./modal2.vue";
import { createOutline } from "ionicons/icons";

export default defineComponent({
  name: "AncillaryInfo",
  components: {
    IonCol,
    IonGrid,
    IonRow,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    return {
      createOutline,
    };
  },
  computed: {
    selectedCounty(): any {
      return this.$store.state.selectedCounty;
    },
    formData(): any {
      return this.$store.state.formData;
    },
  },
  methods: {
    async openModal() {
      if (!this.disabled) {
        const modal = await modalController.create({
          component: Modal,
        });
        modal.present();
        const result = await modal.onDidDismiss();
        if (result && result.data) {
          this.$store.dispatch("saveFromListSelectedCounty", result.data);
        }
      }
    },
    async openModal2() {
      if (!this.disabled) {
        const modal = await modalController.create({
          component: Modal2,
        });
        modal.present();
        const result = await modal.onDidDismiss();
        if (result && result.data) {
          this.$store.dispatch("setFormData", { permitType: result.data });
        }
      }
    },
  },
});
