
import {
  IonButton,
  IonCol,
  IonGrid,
  IonRow,
  IonText,
} from "@ionic/vue";
import { documentTextOutline } from "ionicons/icons";
import { defineComponent } from "vue";
import { dateMixin } from "../../utils/date.format";
import BackButtonLink from "./BackButtonLink.vue";

export default defineComponent({
  name: "CheckExistingList",
  mixins: [dateMixin],
  components: {
    IonButton,
    IonCol,
    IonGrid,
    IonRow,
    IonText,
    BackButtonLink,
  },
  computed: {
    selectedCounty(): any {
      return this.$store.state.selectedCounty;
    },
    getSavedFormData(): any {
      return this.$store.state.formData;
    },
    getLastPermit(): any {
      return this.$store.state.lastPermit;
    },
    today(): any {
      return this.todaysDate();
    },
  },
  setup() {
    return {
      documentTextOutline,
    };
  },
  async mounted() {
    setTimeout(async () => {
      if (this.selectedCounty) {
        await this.$store.dispatch("getLastPermit", this.selectedCounty.id);
      }
    }, 100);
  },
});
