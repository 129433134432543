<template>
<!--1.0.5ish-->
<!--https://github.com/linmasahiro/vue3-table-lite/-->
  <div class="card">
    <div class="card-header" v-if="title">{{ title }}</div>
    <div class="card-body">
      <p class="card-title"></p>
      <div
        id="dataTables-example_wrapper"
        class="dataTables_wrapper dt-bootstrap4 no-footer"
      >
        <div class="row"></div>
        <div class="row">
          <div class="col-sm-12">
            <table
              class="table table-hover table-bordered table-responsive-sm"
              id="dataTables-example"
              width="100%"
            >
              <div class="row"></div>
              <div v-if="isLoading" class="loading-mask">
                <div class="loading-content">
                  <span style="color: white">Loading...</span>
                </div>
              </div>
              <thead class="thead-dark">
                <tr>
                  <th
                    v-for="(col, index) in columns"
                    :key="index"
                    :style="{ width: col.width ? col.width : 'auto' }"
                  >
                    <div
                      :class="{
                        sortable: col.sortable,
                        both: col.sortable,
                        asc:
                          sortable.order == col.field && sortable.sort == 'asc',
                        desc:
                          sortable.order == col.field &&
                          sortable.sort == 'desc',
                      }"
                      @click="col.sortable ? doSort(col.field) : false"
                    >
                      {{ col.label }}
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody v-if="rows.length > 0">
                <tr v-for="(row, i) in rows" :key="i">
                  <td v-for="(col, j) in columns" :key="j">
                    <div v-if="col.display" v-html="col.display(row)"></div>
                    <span v-else>{{ row[col.field] }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- heres the paging footer -->
        <ion-grid>
          <ion-row v-if="rows.length > 0" class="ion-justify-content-end">
            <ion-col size-lg="auto" size="4"> </ion-col>
            <ion-col size-lg="3" size="4" class="ion-align-self-center">
              <span>{{ $t("dashboard.rowsPer") }}</span>
              <select
                v-model="setting.pageSize"
                @change="updatePageSize(setting.pageSize)"
              >
                <option value="2">2</option>
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
              </select>
            </ion-col>
            <ion-col
              size-lg="2"
              size="4"
              class="flex"
              style="justify-content: end"
            >
              <ion-button fill="clear" icon @click="$emit('pagedown')">
                <ion-icon
                  :icon="chevronBackCircleOutline"
                  button
                  size="large"
                ></ion-icon>
              </ion-button>
              <ion-button fill="clear" icon @click="$emit('pageup')">
                <ion-icon
                  @click="$emit('pageup')"
                  :icon="chevronForwardCircleOutline"
                  button
                  size="large"
                ></ion-icon>
              </ion-button>
            </ion-col>
          </ion-row>
          <ion-row v-else>
            <div class="col-sm-12 ion-center-text flex" style="flex-direction: column; opacity: 0.7">
              <div class="auto">
                <ion-icon style="font-size: 72px; opacity: 0.7"
                :icon="informationCircleOutline">
                </ion-icon>
              </div>
              <div class="auto">
                <h4>{{ $t("dashboard.noData") }}</h4>
              </div>
            </div>
          </ion-row>
        </ion-grid>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { IonButton, IonGrid, IonRow, IonCol, IonIcon } from "@ionic/vue";
import {
  chevronForwardCircleOutline,
  chevronBackCircleOutline,
  informationCircleOutline
} from "ionicons/icons";
import { defineComponent, reactive, computed, watch, nextTick } from "vue";
interface TableSetting {
  keyColumn: string;
  pageSize: number;
}
interface TableColumn {
  isKey: string;
  field: string;
}
export default defineComponent({
  name: "DataTableLite",
  components: {
    IonGrid,
    IonRow,
    IonCol,
    IonButton,
    IonIcon,
  },
  emits: [
    "return-checked-rows",
    "do-search",
    "is-finished",
    "pagedown",
    "pageup",
    "pagesize"
  ],
  props: {
    isLoading: {
      type: Boolean,
      require: true,
    },
    isReSearch: {
      type: Boolean,
      require: true,
    },
    title: {
      type: String,
      default: "",
    },
    columns: {
      type: Array,
      default: () => {
        return [];
      },
    },
    rows: {
      type: Array,
      default: () => {
        return [];
      },
    },
    pageSize: {
      type: Number,
      default: 10,
    },
    total: {
      type: Number,
      default: 100,
    },
    page: {
      type: Number,
      default: 1,
    },
    sortable: {
      type: Object,
      default: () => {
        return {
          order: "id",
          sort: "asc",
        };
      },
    },
  },
  methods: {
    updatePageSize(cool: any) {
      this.$emit("pagesize", Number(cool))
    },
  },
  setup(props, { emit }) {
    const setting: TableSetting = reactive({
      keyColumn: computed(() => {
        let key = "";
        Object.assign(props.columns).forEach((col: TableColumn) => {
          if (col.isKey) {
            key = col.field;
          }
        });
        return key;
      }),
      pageSize: props.pageSize,
    });

    const doSort = (order: string) => {
      let sort = "asc";
      if (order == props.sortable.order) {
        if (props.sortable.sort == "asc") {
          sort = "desc";
        }
      }
      emit("do-search", order, sort);
    };

    watch(
      () => props.rows,
      () => {
        if (props.isReSearch) {
          //   setting.page = 1;
        }
        nextTick(function () {
          const localElement = document.getElementsByClassName("is-rows-el");
          emit("is-finished", localElement);
        });
      }
    );

    return {
      setting,
      doSort,
      chevronForwardCircleOutline,
      chevronBackCircleOutline,
      informationCircleOutline
    };
  },
});
</script>

<style scoped>
.both {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAQAAADYWf5HAAAAkElEQVQoz7X QMQ5AQBCF4dWQSJxC5wwax1Cq1e7BAdxD5SL+Tq/QCM1oNiJidwox0355mXnG/DrEtIQ6azioNZQxI0ykPhTQIwhCR+BmBYtlK7kLJYwWCcJA9M4qdrZrd8pPjZWPtOqdRQy320YSV17OatFC4euts6z39GYMKRPCTKY9UnPQ6P+GtMRfGtPnBCiqhAeJPmkqAAAAAElFTkSuQmCC");
}
.sortable {
  cursor: pointer;
  background-position: right;
  background-repeat: no-repeat;
  padding-right: 30px !important;
}
.asc {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAByUDbMAAAAZ0lEQVQ4y2NgGLKgquEuFxBPAGI2ahhWCsS/gDibUoO0gPgxEP8H4ttArEyuQYxAPBdqEAxPBImTY5gjEL9DM+wTENuQahAvEO9DMwiGdwAxOymGJQLxTyD+jgWDxCMZRsEoGAVoAADeemwtPcZI2wAAAABJRU5ErkJggg==);
}
.desc {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAByUDbMAAAAZUlEQVQ4y2NgGAWjYBSggaqGu5FA/BOIv2PBIPFEUgxjB+IdQPwfC94HxLykus4GiD+hGfQOiB3J8SojEE9EM2wuSJzcsFMG4ttQgx4DsRalkZENxL+AuJQaMcsGxBOAmGvopk8AVz1sLZgg0bsAAAAASUVORK5CYII=);
}
.loading-mask {
  position: absolute;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-flow: column;
  transition: opacity 0.3s ease;
}
.loading-content {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  padding: 20px;
}
select {
  width: auto;
  border: 1px solid #cccccc;
  background-color: #ffffff;
  height: auto;
  padding: 0px;
  margin-bottom: 0px;
}
table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
  border-collapse: collapse;
}
th {
  text-align: inherit;
}
tr {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
}
.table .thead-dark th {
  color: #fff;
  background-color: #222328;
  border-color: #454d55;
}
.table-bordered thead td,
.table-bordered thead th {
  border-bottom-width: 2px;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}
.table-bordered td,
.table-bordered th {
  border: 1px solid #dee2e6;
}
.table td,
.table th {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}
.table-border {
  border: 1px solid #dee2e6;
}
.table td,
.table th {
  vertical-align: middle;
}
.table-bordered td,
.table-bordered th {
  border: 1px solid #dee2e6;
}
.table td,
.table th {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}
.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

*,
::after,
::before {
  box-sizing: border-box;
}
@media (min-width: 576px) {
  .col-sm-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media (min-width: 768px) {
  .col-md-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
}
</style>