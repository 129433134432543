
import { IonCol, IonRow, IonText } from "@ionic/vue";

import { defineComponent } from "vue";

export default defineComponent({
  name: "BackButtonLink",
  components: {
    IonCol,
    IonRow,
    IonText,
  },
  props: {
    route: {
      type: String,
      default: null
    }
  }
});
