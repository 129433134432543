<template>
  <ion-grid>
    <ion-row>
      <ion-col size-lg="3"> </ion-col>
      <ion-col size-lg="6" size="12">
          <h5 class="ion-padding-bottom"><strong>User Details</strong></h5>
        <ion-list v-if="loaded" class="ion-padding">
              <ion-item class="ion-no-padding">
                <ion-label position="floating">{{
                  $t("login.emailLabel")
                }}</ion-label>
                <ion-input
                v-if="getUser"
                  name="username"
                  autocomplete
                 v-model="getUser.email"
                 disabled
                  enterkeyhint="next"
                  inputmode="email"
                ></ion-input>
              </ion-item>
              <ion-item class="ion-no-padding">
                <ion-label position="floating">Old Password</ion-label>
                <ion-input
                  name="oldpassword"
                  type="password"
                  value="12312"
                  disabled
                  enterkeyhint="next"
                ></ion-input>
              </ion-item>
              <ion-item class="ion-no-padding">
                <ion-label position="floating">New Password</ion-label>
                <ion-input
                  name="newpassword"
                  type="password"
                  disabled
                  enterkeyhint="next"
                ></ion-input>
              </ion-item>
              <ion-item class="ion-no-padding" lines="none">
                <ion-label position="floating">Verify New Password</ion-label>
                <ion-input
                  name="verifypassword"
                  type="password"
                  disabled
                  enterkeyhint="next"
                ></ion-input>
              </ion-item>
        </ion-list>
      </ion-col>
      <ion-col size-lg="3"> </ion-col>
    </ion-row>
          <ion-row class="ion-padding-top">
        <ion-col  size-lg="3"> </ion-col>
        <ion-col size-lg="6"  size="12">
          <div class="w100 flex">
            <ion-button
              color="primary"
              :disabled="!loaded"
              style="margin: 0px 0px 0px auto"
              >Update Password</ion-button
            >
          </div>
        </ion-col>
        <ion-col  size-lg="3"> </ion-col>
      </ion-row>
  </ion-grid>

</template>
<script lang='ts'>
import {
  IonButton,
  IonCol,
  IonGrid,
  IonItem,
  IonInput,
  IonLabel,
  IonList,
  IonRow,
} from "@ionic/vue";
import {
  saveOutline,
  lockOpenOutline,
  lockClosedOutline,
} from "ionicons/icons";

import { defineComponent, reactive, ref } from "vue";

export default defineComponent({
  name: "UserSettings",
  props: {
    initFormData: {
      type: Object,
      default: null,
    },
  },
  components: {
    IonButton,
    IonCol,
    IonGrid,
    IonItem,
    IonInput,
    IonLabel,
    IonList,
    IonRow,
  },
  setup(props: any) {
    const loaded = ref(false);
    const submitting = ref(false);
    const success = ref(false);
    const errorTxt = ref('');
    const edit = ref(false);
    const formData = reactive(Object.assign({}, props.initFormData));
    let t: any;
    return {
      formData,
      t,
      loaded,
      submitting,
      success,
      errorTxt,
      edit,
      saveOutline,
      lockOpenOutline,
      lockClosedOutline,
    };
  },
  computed: {
    selectedCounty(): any {
      return this.$store.state.selectedCounty;
    },
    getSavedFormData(): any {
      return this.$store.state.formData;
    },
    getUser(): any {
      return this.$store.state.myUser
    }
  },
  mounted() {
    // we need to wait for the store to get crap outta storage.
    setTimeout(() => {
      this.loaded = true;
    }, 300);
  },
  methods: {
    async checkForm() {
      this.submitting = true;
      const errorMsg = '';
      if (errorMsg) {
        this.errorTxt = errorMsg;
        this.success = false;
      } else {
        this.errorTxt = 'Update Successful'
        this.success = true;
      }
      this.submitting = false;
      setTimeout(() => {
           this.errorTxt = "";
        }, 2500);
    },
  },
  watch: {
    initFormData(val) {
      this.formData = val
    }
  }
});
</script>
