
import { defineComponent } from "vue";
import { IonGrid, IonRow, IonCol } from "@ionic/vue";
import DashboardCard from "./DashboardCard.vue";

export default defineComponent({
  name: "PermitDashboard",
  components: {
    IonGrid,
    IonRow,
    IonCol,
    DashboardCard,
  },
  computed: {
    selectedCounty(): any {
      return this.$store.state.selectedCounty;
    },
  }
});
