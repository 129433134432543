/*
CUSTOMER MODEL
{
    lastUpdated June 22, 2021 at 12:00:00 PM UTC-4
    stripeCardType ""
    stripeCustomerId ""
    stripeCustomerPaymentMethodId ""
    stripeExpMonth ""
    stripeExpYear ""
    stripeLastFour ""
}
*/

import {FirestoreDate} from './permit';

export class Customer {
  constructor(
        public lastUpdated?: FirestoreDate,
        public stripeCardType?: string,
        public stripeCustomerId?: string,
        public stripeCustomerPaymentMethodId?: string,
        public stripeExpMonth?: string,
        public stripeExpYear?: string,
        public stripeLastFour?: string,
        public stripeSubscriptionId?: string,
        public id?: string
  ) { }
}

export const customerConverter = {
  toFirestore: function(c: Customer) {
    return {
      lastUpdated: c.lastUpdated,
      stripeCardType: c.stripeCardType,
      stripeCustomerId: c.stripeCustomerId,
      stripeCustomerPaymentMethodId: c.stripeCustomerPaymentMethodId,
      stripeExpMonth: c.stripeExpMonth,
      stripeExpYear: c.stripeExpYear,
      stripeLastFour: c.stripeLastFour,
      stripeSubscriptionId: c.stripeSubscriptionId,
      id: c.id,
    };
  },

  fromFirestore: function(snapshot: any, options: any) {
    const data = snapshot.data(options);
    return new Customer(
        data.lastUpdated,
        data.stripeCardType,
        data.stripeCustomerId,
        data.stripeCustomerPaymentMethodId,
        data.stripeExpMonth,
        data.stripeExpYear,
        data.stripeLastFour,
        data.stripeSubscriptionId,
        data.id
    );
  },
};
