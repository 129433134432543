export const sixCharacters = (value: string) => {

    if (!value || value.length < 6) {
      return "Password must be at least 6 characters";
    }

    return true;
  }
export const validateEmail = (value: string) => {
  // if the field is empty
  if (!value) {
    return 'Email is required';
  }

  if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
    return 'This field must be a valid email';
  }

  // All is good
  return true;
}

export const isRequired = (value: any) => {
  if (!value) {
    return 'This field is required';
  }
  return true;
}

export const addressFake = (value: string) => {
  if (!value || value.length < 6) {
    return "Please enter a unit number and street address";
  }
  return true;
}

export const threeCharacters = (value: string) => {
  if (!value || value.length < 3) {
    return "Please enter a city";
  }
  return true;
}

export const validatePhone = (value: string) => {
  // if the field is empty
  if (!value) {
    return 'Phone number is required';
  }

  if (!value || value.length < 14) {
    return "Phone number must be 10 digits. Example: (555) 555-555";
  }

  // if (!/^[2-9]\d{2}[2-9]\d{2}\d{4}$/i.test(value)) {
  //   return 'This field must be a valid Georgia phone number';
  // }

  // All is good
  return true;
}
export const validateZipCode = (value: string) => {
  // if the field is empty
  if (!value) {
    return 'Zip code is required';
  }

  // if the field is not a valid email
  if (!/^\d{5}(?:[-\s]\d{4})?$/i.test(value)) {
    return  "Must be valid zip code";
  }
  return true;
}
export const max500Chars = (value: string) => {
  // if the field is empty
  if (!value) {
    return true; // optional field
  } else {
    if (value.length > 499) {
      return "Info must be less than 500 ";
    }
  }
  return true;
}

export const formatPhoneNumber = (phoneNumberString: string) => {
  const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }
  return cleaned;
}
