/*
COUNTY MODEL
    {
        additionalConditions "brush only"
        contactAddress "123 the past st, banks, georgia, 30547,"
        contactFirstName "Adam"
        contactLastName "Smith"
        contactPhone "5555555555"
        contactTitle "Chief"
        enabled false
        name "Banks"
        photoUrl "" 
        permitsTotal
        permitsThisMonth
        permitsThisWeek
    }
*/

export class County {
    constructor(
        public name?: string,
        public contactFirstName?: string,
        public contactLastName?: string,
        public contactTitle?: string,
        public contactAddress?: string,
        public contactPhone?: string,
        public enabled?: boolean,
        public photoUrl?: string,
        public additionalConditions?: string,
        public permitsTotal?: number,
        public permitsThisMonth?: number,
        public permitsThisWeek?: number,
        public id?: string
    ) { }
}

// Firestore data converter
export const countyConverter = {
    //to
    toFirestore: function (county: County) {
        return {
            name: county.name,
            contactFirstName: county.contactFirstName,
            contactLastName: county.contactLastName,
            contactTitle: county.contactTitle,
            contactAddress: county.contactAddress,
            contactPhone: county.contactPhone,
            enabled: county.enabled,
            photoUrl: county.photoUrl,
            additionalConditions: county.additionalConditions,
            permitsTotal : county.permitsTotal,
            permitsThisMonth : county.permitsThisMonth,
            permitsThisWeek : county.permitsThisWeek,
            id: county.id
        };
    },
    // firebase.firestore.QuerySnapshot<County>
    fromFirestore: function (snapshot: any, options: any) {
        const data = snapshot.data(options);
        return new County(data.name,
            data.contactFirstName,
            data.contactLastName,
            data.contactTitle,
            data.contactAddress,
            data.contactPhone,
            data.enabled,
            data.photoUrl,
            data.additionalConditions,
            data.permitsTotal,
            data.permitsThisMonth,
            data.permitsThisWeek,
            data.id
        );
    }
};