
import { defineComponent } from "vue";

export default defineComponent({
  name: "DashboardCard",
  props: {
    label: {
      type: String,
      default: "",
    },
    value: {
      type: Number,
      default: null,
    },
  },
});
