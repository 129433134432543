import { FirestoreDate } from "./permit";

/*
COUNTY ORDINANCE MODEL
    {
        sections ["spring burn ban"],
        lastUpdated: {seconds: 12312, nanoseconds: 2312}
    }
*/
export class Ordinance {
    constructor(
        public lastUpdated?: FirestoreDate,
        public sections?: string[],
        public id?: string
    ) { }
}

// Firestore data converter
export const ordinanceConverter = {
    toFirestore: function (exOrd: Ordinance) {
        return {
            lastUpdated: exOrd.lastUpdated,
            sections: exOrd.sections,
            id: exOrd.id
        };
    },
    // firebase.firestore.QuerySnapshot<Ordinance>
    fromFirestore: function (snapshot: any, options: any) {
        const data = snapshot.data(options);
        return new Ordinance(
            data.lastUpdated,
            data.sections,
            data.id
        );
    }
};