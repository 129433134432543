<template>
  <div class="ion-padding-horizontal accent pt-3 w100" style="overflow: hidden">
    <div class="flex">
      <div class="flex w100" style="max-height: 10vh; position: relative">
        <ion-button
          v-show="getUser"
          @click="openMenu"
          class="pointer"
          fill="clear"
          style="position: absolute; top: 0; right: 0; color: white; z-index: 1"
        >
          <ion-text color="secondary-contrast">
            {{ getUsername }}
          </ion-text>
          <ion-icon
            class="ion-padding-start"
            color="secondary-contrast"
            :icon="menuOutline"
            size="large"
          ></ion-icon>
        </ion-button>
        <img
          :style="{ height: scrollTop > 50 ? '48px' : '84px' }"
          style="max-width: 576px; transition: height 0.1s linear"
          @click="$router.push('/')"
          src="/../assets/georgia-logo.svg"
          class="auto align-self-center pointer"
          contain
        />
      </div>
    </div>
  </div>
</template>

<script lang='ts'>
import {
  IonButton,
  IonIcon,
  IonText,
  menuController,
} from "@ionic/vue";
import { menuOutline } from "ionicons/icons";
import { defineComponent } from "vue";

export default defineComponent({
  name: "LogoHeader",
  components: {
    IonButton,
    IonIcon,
    IonText,
  },
  setup() {
    return { menuOutline };
  },
  computed: {
    getUser(): any {
      return this.$store.state.myUser;
    },
    getUsername(): any {
      const a = this.getUser ? this.getUser.email.split("@") : [""];
      return a[0];
    },
    scrollTop() {
      return this.$store.state.scrollTop;
    },
  },
  methods: {
    openMenu() {
      menuController.enable(true, "first");
      menuController.open("first");
    },
  },
  mounted() {
    this.$store.dispatch("setScroll", 0)
  }
});
</script>
