
import { dateMixin } from "@/utils/date.format";
import { IonRow, IonCol, IonIcon } from "@ionic/vue";
import { defineComponent, ref } from "vue";
import { informationCircleOutline } from "ionicons/icons"

export default defineComponent({
  name: "WeatherAlerts",
  mixins: [dateMixin],
  setup() {
    const loaded = ref(false)
    const errorMsg = ref('')
    return { loaded, errorMsg, informationCircleOutline}
  },
  components: {
    IonIcon,
    IonRow,
    IonCol,
  },
  computed: {
    weatherAlerts(): any {
      return this.$store.state.weatherAlerts;
    },
  },
  mounted() {
    this.load();
  },
  methods: {
   async load() {
      this.errorMsg = await this.$store.dispatch("getWeatherAlerts");
      setTimeout(() => {
        this.loaded = true
      }, 400) // empty state delay.
    },
  },
});
