<template>
  <ion-header>
    <ion-toolbar
      color="secondary" class="pt-2 pb-2"
    >
      <ion-title>{{$t("apply.selectCounty")}}</ion-title>
      <ion-buttons slot="end">
        <ion-icon
          @click="closeMe(null)"
          class="pr-2 pointer"
          button
          :icon="closeOutline"
          size="large"
        />
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding-bottom ion-padding-end">
    <ion-list class="ion-padding-horizontal">
      <ion-list-header>
        <ion-searchbar
          v-model="search"
          :placeholder="$t('apply.searchCounty')"
          show-cancel-button="never"
          color="light"
          debounce="300"
          @ionChange="searchCounties"
        ></ion-searchbar>
      </ion-list-header>
      <div class="ion-padding"></div>
      <ion-item v-for="c in counties" :key="c.name" @click="closeMe(c)" class="pointer" button>
        <ion-label>
          <h2>{{c.name}}</h2>
          <p>{{c.additionalConditions}}</p>
        </ion-label>
      </ion-item>
    </ion-list>
  </ion-content>
</template>

<script lang='ts'>
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonList,
  IonListHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonTitle,
  IonSearchbar,
  IonToolbar,
  modalController
} from "@ionic/vue";
import { defineComponent, ref} from "vue";
import { County } from "@/models/county";
import { closeOutline } from "ionicons/icons";

export default defineComponent({
  name: "Modal",
  setup() {
    const search = ref('')
    return { closeOutline, search };
  },
  components: {
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonList,
    IonListHeader,
    IonItem,
    IonLabel,
    IonSearchbar,
    IonTitle,
    IonToolbar
  },
  computed: {
    counties(): any {
      return this.$store.state.counties;
    },
  },
  mounted() {
    if (!this.counties || !this.counties.length) {
      this.searchCounties()
    } 
  },
  methods: {
    closeMe(c: County) {
      modalController.dismiss(c)
    },
    searchCounties() {
      this.$store.dispatch("getCounties", this.search ? this.search.trim() : '')
    }
  }
});
</script>