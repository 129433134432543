import { DateTime } from "luxon";

export const tableColumns = [
    {
      label: "ID",
      field: "permitId",
      width: "10%",
      sortable: true,
      isKey: true,
    },
    {
      label: "Type",
      field: "permitType",
      width: "5%",
      //sortable: true,
    },
    {
      label: "Expiry",
      field: "submittedAt",
      width: "10%",
      display: (row: any) => {
        const valid =
          DateTime.fromSeconds(row.submittedAt.seconds).plus({ days: 1 }) >
          DateTime.now();
        return (
          "<div " +
          'class="' +
          (valid ? "text--success" : "text--danger") +
          '" ' +
          ">" +
          DateTime.fromSeconds(row.submittedAt.seconds)
            .plus({ days: 1 })
            .toLocaleString(DateTime.DATETIME_FULL) +
          "</div>"
        );
      },
    },
    {
      label: "Phone No",
      field: "phoneNo",
      width: "8%",
      //sortable: true,
    },
    {
      label: "Address",
      field: "address",
      width: "20%",
      display: function (row: any) {
        return (
          "<div>" +
          row.address +
          ", " +
          row.city +
          ", " +
          row.zip +
          "</div>"
        );
      },
    },
    {
      label: "Name",
      field: "lastName",
      width: "8%",
      display: function (row: any) {
        return "<div>" + row.firstName + " " + row.lastName + "</div>";
      },
    },
    {
      label: "Email",
      field: "email",
      width: "8%",
      //sortable: true,
    },
    {
      label: "Details",
      width: "5%",
      display: function (row: any) {
        return (
          '<a type="button" data-id="' +
          row.permitId +
          '" class="pointer text--primary is-rows-el quick-btn">DETAILS</a>'
        );
      },
    },
  ];