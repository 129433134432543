<template>
  <ion-header>
    <ion-toolbar
      color="secondary" class="pt-2 pb-2"
    >
      <ion-title>{{$t("onboard.billingForm")}}</ion-title>
      <ion-buttons slot="end">
        <ion-icon
          @click="closeMe(null)"
          class="pr-2 pointer"
          button
          :icon="closeOutline"
          size="large"
        />
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding-bottom ion-padding-end">
    <form     @submit.prevent="
      onSubmit(vales);
    ">
    <ion-grid>
      <ion-row>
        <ion-col size="12">
          <ion-list class="ion-no-padding ion-padding-top" style="background: transparent">
            <ion-item style="background: white">
              <ion-label position="floating">{{
                $t("onboard.nameOnCard")
              }}</ion-label>
              <ion-input
                debounce="500"
                @ionChange="nameOnCard = nameOnCard.trim().toUpperCase()"
                autocomplete
                v-model="nameOnCard"
                name="nameOnCard"
                type="text"
                enterkeyhint="next"
                inputmode="text"
                maxlength="50"
              ></ion-input>
            </ion-item>
            <input-error
              v-if="nameOnCardError"
              :show="nameOnCard"
              :msg="nameOnCardError"
            />
            <ion-item style="background: white">
              <ion-label position="floating">{{
                $t("permit.address")
              }}</ion-label>
              <ion-input
                debounce="500"
                @ionChange="address = address.toUpperCase()"
                autocomplete
                v-model="address"
                name="address"
                type="text"
                enterkeyhint="next"
                inputmode="text"
                maxlength="500"
              ></ion-input>
            </ion-item>
            <input-error
              v-if="addressError"
              :show="address"
              :msg="addressError"
            />
          </ion-list>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="12"  style="background: white">
          <stripe-box
             type="submit"
            @paymentMethod="updatePayment"
            :nameOnCard="nameOnCard"
            :address="address"
            buttonText="UPDATE PAYMENT DETAILS"
          />
        </ion-col>
      </ion-row>
      <ion-row class="ion-padding"> </ion-row>
    </ion-grid>
      </form>
  </ion-content>

</template>

<script lang='ts'>
import {
  IonButtons,
  IonCol,
  IonRow,
  IonInput,
  IonContent,
  IonHeader,
  IonGrid,
  IonList,
  IonIcon,
  IonItem,
  IonLabel,
  IonTitle,
  IonToolbar,
  modalController,
} from "@ionic/vue";
import { defineComponent } from "vue";
import { County } from "@/models/county";
import { closeOutline } from "ionicons/icons";
import InputError from "./../permit/InputError.vue";
import { useForm, useField } from "vee-validate";
import { isRequired } from "@/utils/form.validators";
import StripeBox from "./../onboard/StripeBox.vue";
import { useSnackbarPlugin } from "snackbar-vue";

export default defineComponent({
  name: "UpdateBillingModal",
  components: {
    IonButtons,
    IonCol,
    IonRow,
    IonInput,
    IonContent,
    IonHeader,
    IonGrid,
    IonIcon,
    IonList,
    IonItem,
    IonLabel,
    IonTitle,
    IonToolbar,
    StripeBox,
    InputError,
  },
  setup() {
    const { handleSubmit } = useForm();
    const onSubmit = handleSubmit(() => {
      //
    });
    const snack = useSnackbarPlugin();

    const { value: nameOnCard, errorMessage: nameOnCardError } = useField(
      "nameOnCard",
      isRequired
    );
    const { value: address, errorMessage: addressError } = useField(
      "address",
      isRequired
    );
    const { value: paymentMethod, errorMessage: paymentMethodError } = useField(
      "paymentMethod",
      isRequired
    );

    return {
      nameOnCard,
      address,
      paymentMethod,
      addressError,
      nameOnCardError,
      paymentMethodError,
      onSubmit,
      snack,
      closeOutline,
    };
  },
  methods: {
    closeMe(c: any) {
      modalController.dismiss(c);
    },
    async updatePayment(paymentMethod: any) {
      // submit the form if we have everything ,
      if (!paymentMethod || !this.address || !this.nameOnCard) {
        this.snack.show({
          position: "bottom",
          text: "Please check submission.",
          time: 2500,
          close: true,
        });
        return;
      }
      // show snack saying submitting..
      this.snack.show({
        position: "bottom",
        text: "Submitting..",
        time: 2500,
        close: true,
      });

      // do something...
      const errorMsg = await this.$store.dispatch("updateCustomer", {
        nameOnCard: this.nameOnCard,
        address: this.address,
        paymentMethod: paymentMethod,
      });

      if (errorMsg) {
        this.snack.show({
          position: "bottom",
          text: errorMsg,
          time: 2500,
          close: true,
        });
        return;
      }

      await this.$store.dispatch("loadCustomer");

      this.snack.show({
        position: "bottom",
        text: "Success. Payment Information Updated.",
        time: 2500,
        close: true,
      });
      this.closeMe(null);
    },
  },
});
</script>