
import ApplicationForm from "@/components/permit/ApplicationForm.vue";
import {
  IonContent,
  IonCol,
  IonFooter,
  IonGrid,
  IonRow,
  IonPage,
  IonProgressBar,
} from "@ionic/vue";
import { defineComponent, ref } from "vue";
import LogoHeader from "../components/LogoHeader.vue";
import FirePermitForm from "../components/permit/FirePermitForm.vue";
import GbpFooter from "@/components/Footer.vue";

export default defineComponent({
  name: "PermitPage",
  components: {
    FirePermitForm,
    IonContent,
    IonCol,
    IonRow,
    IonGrid,
    IonPage,
    IonFooter,
    IonProgressBar,
    LogoHeader,
    ApplicationForm,
    GbpFooter
  },
  setup() {
    const loading = ref(false)
    const content = ref()
    return { loading, content }
  },
  async updated() {
    this.content.$el.scrollToTop()
  },
  async mounted() {
    this.$store.dispatch("initFromStorage");
  },
  methods: {
    async onSubmit(values: any) {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
        this.$router.push("/check");
      }, 500)
    },
  },
});
