<template>
  <div class="ion-text-center">
    <h2 class="ion-padding-bottom">{{ $t("admin.burnCalendarTitle") }}</h2>
    <Calendar
      color="orange"
      class="gbp-calendar"
      :masks="getCalendarData.masks"
      :attributes="getCalendarData.attributes"
      disable-page-swipe
      is-expanded
    >
      <template v-slot:day-content="{ day, attributes }">
        <div class="w100 ion-text-start">
          {{ day.day }}
        </div>
        <div class="flex">
          <div
            class="w100"
            @click="openPop(day, attributes[0].customData.exDate, $event)"
          >
            <div
              v-for="attr in attributes"
              :key="attr.key"
              class="flex-column pointer"
            >
              <ion-icon
                class="auto"
                color="danger"
                size="large"
                :icon="closeCircleOutline"
              ></ion-icon>
              <p class="reason-block ion-text-center text--danger">
                <strong>{{ truncCalendarText(attr.customData.exDate.reason) }}</strong>
              </p>
            </div>
          </div>
        </div>
      </template>
    </Calendar>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { Calendar } from "v-calendar";
import CalendarDayPopover from "./CalendarDayPopover.vue";
import { popoverController } from "@ionic/vue";
import { ExcludedDate } from "@/models/excluded";
import { closeCircleOutline } from "ionicons/icons";
import { IonIcon } from "@ionic/vue";
export default defineComponent({
  name: "BurnCalendar",
  components: {
    Calendar,
    IonIcon
  },
  emits: ["remove"],
  setup() {
    return { closeCircleOutline };
  },
  computed: {
    excludedDates(): any[] {
      return this.$store.state.selectedCountyExcludedDates;
    },
    toAttributes(): any[] {
      if (this.excludedDates && this.excludedDates.length) {
        const o: any[] = [];
        this.excludedDates.forEach((exDate: ExcludedDate) => {
          o.push({
            key: 2,
            customData: {
              exDate: exDate,
            },
            dates: new Date((exDate.date!.seconds ?? 0) * 1000),
          });
        });
        return o;
      }
      return [];
    },
    getCalendarData(): any {
      return {
        masks: {
          weekdays: "WWW",
        },
        attributes: this.toAttributes,
      };
    },
  },
  methods: {
    // this date: someCool type from
    async openPop(date: any, exDate: ExcludedDate, $event: Event) {
      const popover = await popoverController.create({
        component: CalendarDayPopover,
        componentProps: {
          event: $event,
          dateString: date.ariaLabel,
          exDate: exDate,
        },
      });
      await popover.present();

      const { data } = await popover.onDidDismiss();
      if (data) {
        this.$emit("remove", data);
      }
    },
    truncCalendarText(text: string) {
      if (!text) return "";
      const a = text.length > 25 ? "..." : "";
      return text.slice(0, 25) + a;
    },
  },
});
</script>

<style lang="scss" >
.reason-block {
  margin: 0;
  padding: 4px;
  font-size: 12px;
}

.gbp-calendar {
  overflow-x: scroll;
}
/*TODO are these leaky global */
::-webkit-scrollbar {
  width: 0px;
}
::-webkit-scrollbar-track {
  display: none;
}
.gbp-calendar.vc-container {
  --day-border: 1px solid #b8c2cc;
  --day-border-highlight: 1px solid #b8c2cc;
  --day-width: 120px;
  --day-height: 90px;
  --weekday-bg: #f8fafc;
  --weekday-border: 1px solid #eaeaea;
  border-radius: 0;
  width: 100%;
  & .vc-header {
    background-color: #f1f5f8;
    padding: 10px 0;
  }
  & .vc-weeks {
    padding: 0;
  }
  /* this is the days of the week bar) */
  & .vc-weekday {
    background-color: var(--weekday-bg);
    border-bottom: var(--weekday-border);
    border-top: var(--weekday-border);
    padding: 5px 0;
  }
  & .vc-day {
    padding: 0 5px 3px 5px;
    text-align: left;
    height: var(--day-height);
    min-width: var(--day-width);
    width: var(--day-width);
    background-color: white;
    &.weekday-1,
    &.weekday-7 {
      background-color: #f4f5f8;
    }
    &:not(.on-bottom) {
      border-bottom: var(--day-border);
      &.weekday-1 {
        border-bottom: var(--day-border-highlight);
      }
    }
    &:not(.on-right) {
      border-right: var(--day-border);
    }
  }
  & .vc-day-dots {
    margin-bottom: 5px;
  }
  & .vc-bars {
    width: 100%;
  }
}
</style>