
import ApplicationForm from "@/components/permit/ApplicationForm.vue";
import ArePermitsAvailable from "@/components/permit/ArePermitsAvailable.vue";
import { dateMixin } from "@/utils/date.format";
import {
  IonButton,
  IonContent,
  IonGrid,
  IonCol,
  IonRow,
  IonPage,
} from "@ionic/vue";
import { defineComponent } from "vue";
import LogoHeader from '../components/LogoHeader.vue';
import AncillaryInfo from '../components/permit/AncillaryInfo.vue';
// import BackButtonLink from '../components/permit/BackButtonLink.vue';
import GbpFooter from "@/components/Footer.vue";

export default defineComponent({
  name: "ApplicationPage",
  mixins: [dateMixin],
  components: {
    AncillaryInfo,
    // BackButtonLink,
    IonButton,
    IonCol,
    IonRow,
    IonGrid,
    IonContent,
    IonPage,
    LogoHeader,
    ApplicationForm,
    ArePermitsAvailable,
    GbpFooter
  },
  mounted() {
    this.$store.dispatch("initFromStorage");
  },
  computed: {
    selectedCounty(): any {
      return this.$store.state.selectedCounty;
    },
    excludedDates(): any[] {
      return this.$store.state.selectedCountyExcludedDates;
    },
    areAvailable(): boolean {
      return !this.reason;
    },
    reason(): string {
      let reason = '';
        if (this.excludedDates && this.excludedDates.length) {
          this.excludedDates.forEach(
            (d: { date: { seconds: number; nanoseconds: number }; reason: string }) => {
              if (this.isSameAsToday(d.date.seconds)) {
                reason = d.reason;
              }
            }
          );
      }
      if (this.selectedCounty && !this.selectedCounty.enabled) {
        reason = 'Permits are not available for this county yet.'
      }
      return reason;
    },
  },
  watch: {
    // didn't seem to be aware of changes otherwise
    selectedCounty(val) {
      // console.log('yo')
    }
  }
});
