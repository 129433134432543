<template>
  <ion-grid>
    <ion-row class="ion-padding-horizontal">
      <ion-col size-md="2" size="auto"> </ion-col>
      <ion-col
        size-md="8"
        class="
          ion-text-left
          flex
          ion-justify-content-between
          ion-align-items-center
          ion-padding-horizontal
          ion-padding-top
        "
        :style="{ background: '#FFFFFF', opacity: disabled ? '0.5' : '1' }"
      >
        <ion-label>
          <strong>{{ $t("review.yourInfo") }}</strong></ion-label
        >
      </ion-col>
      <ion-col size-md="2" size="auto"> </ion-col>
    </ion-row>
    <ion-row class="ion-padding-horizontal ion-padding-bottom">
      <ion-col size-md="2" size="auto"> </ion-col>
      <ion-col
        size-md="8"
        class="
          ion-text-left
          flex
          ion-justify-content-between ion-align-items-center
        "
        :style="{
          background: '#FFFFFF',
          padding: '0px 16px 24px 16px',
          opacity: disabled ? '0.5' : '1',
        }"
      >
        <div>
          <h1 v-if="formData" class="ion-no-margin">
            {{
              formData.firstName
                ? formData.firstName + " " + formData.lastName
                : "N/A"
            }}
          </h1>
          <h4 v-if="formData" class="ion-no-margin">
            {{
              formData.address
                ? formData.address +
                  " " +
                  (formData.city ? formData.city : "") +
                  " " +
                  (formData.zip ? formData.zip : "") +
                  " GA"
                : ""
            }}
          </h4>
          <h4 v-if="formData" class="ion-no-margin">
            {{ formData.email ? formData.email + " " : ""
            }}{{ formData.phoneNo ? formData.phoneNo + " " : "" }}
          </h4>
        </div>
        <div class="auto"></div>
        <div class="ion-align-self-end">
          <ion-button fill="clear" @click="openModal3">
            <ion-icon
              class="text--accent pointer"
              :icon="createOutline"
              size="large"
            ></ion-icon>
          </ion-button>
        </div>
      </ion-col>
      <ion-col size-md="2" size="auto"> </ion-col>
    </ion-row>
  </ion-grid>
</template>

<script lang='ts'>
import { IonButton, IonGrid, IonRow, IonCol, IonIcon, IonLabel, modalController } from "@ionic/vue";
import { defineComponent } from "vue";
import Modal3 from "./modal3.vue";
import { createOutline } from "ionicons/icons";
import { useSnackbarPlugin } from "snackbar-vue";

export default defineComponent({
  name: "InfoReviewBlock",
  components: {
    IonButton,
    IonCol,
    IonGrid,
    IonIcon,
    IonRow,
    IonLabel
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const snack = useSnackbarPlugin();
    return {
      snack,
      createOutline,
    };
  },
  computed: {
    formData(): any {
      return this.$store.state.formData;
    },
  },
  methods: {
    async openModal3() {
      if (!this.disabled) {
        const modal = await modalController.create({
          componentProps: {
            initFormData: this.formData,
          },
          component: Modal3,
        });
        modal.present();
        const result = await modal.onDidDismiss();
        if (result && result.data) {
          this.$store.dispatch("setFormData", result.data);
          this.snack.show({
            position: "bottom",
            text: this.$t("review.informationUpdated"),
            time: 2500,
            close: true,
          });
        }
      }
    },
  },
});
</script>
