
import { IonApp, IonRouterOutlet } from "@ionic/vue";
import { defineComponent, ref } from "vue";
import MainMenu from "./components/admin/MainMenu.vue";

export default defineComponent({
  name: "App",
  components: {
    IonApp,
    IonRouterOutlet,
    MainMenu,
  },
  setup() {
    const loading = ref(true);
    return { loading };
  },
  computed: {
    getUser(): any {
      return this.$store.state.myUser;
    },
    getCustomer(): any {
      return this.$store.state.myCustomer;
    },
  },
  mounted() {
    this.$store.dispatch("initFromStorage");
    setTimeout(() => {
      this.loading = false;
    }, 200);
  },
  beforeCreate() {
    this.$router.beforeEach(async (to, from, next) => {
      const authdPath = [
        "/onboard",
        "/dashboard",
        "/dashboard/permits",
        "/dashboard/weather",
        "/dashboard/counties",
        "/admin/details",
        "/admin/calendar",
        "/admin/ordinance",
        "/settings/profile",
        "/settings/billing",
        "/settings/counties",
      ].findIndex((i) => i === to.path);
      if (authdPath > -1 && !this.getUser) {
        next({ path: "/login" });
      } else if (
        (authdPath > -1 && this.getUser && !this.getCustomer) ||
        (authdPath > -1 && this.getUser && !this.getCustomer.stripeCustomerId)
      ) {
        if (to.path == "/onboard" || this.getCustomer) {
          next();
        }
        next({ path: "/onboard" });
      } else {
        next();
      }
    });
  },
});
