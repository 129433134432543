
import {
  IonButton,
  IonCol,
  IonGrid,
  IonItem,
  IonInput,
  IonTextarea,
  IonLabel,
  IonList,
  IonRow,
  IonCheckbox,
  IonText,
} from "@ionic/vue";

import { defineComponent, ref } from "vue";

import BackButtonLink from "./BackButtonLink.vue";
import InputError from "./InputError.vue";
import { useForm, useField } from "vee-validate";
import {
  validateEmail,
  isRequired,
  validatePhone,
  validateZipCode,
  max500Chars,
  formatPhoneNumber,
} from "@/utils/form.validators";

export default defineComponent({
  name: "FirePermitForm",
  props: {
    submitHandler: null, // the parent is in charge.
    loading: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    IonButton,
    IonCheckbox,
    IonCol,
    IonGrid,
    IonItem,
    IonInput,
    IonText,
    IonTextarea,
    IonLabel,
    IonList,
    IonRow,
    BackButtonLink,
    InputError,
  },
  setup(props) {
    const showErrors = ref(false);
    const { handleSubmit } = useForm();
    const onSubmit = handleSubmit(props.submitHandler);

    const { value: firstName, errorMessage: firstNameError } = useField(
      "firstName",
      isRequired
    );
    const { value: lastName, errorMessage: lastNameError } = useField(
      "lastName",
      isRequired
    );
    const { value: address, errorMessage: addressError } = useField(
      "address",
      isRequired
    );
    const { value: city, errorMessage: cityError } = useField(
      "city",
      isRequired
    );
    const { value: zip, errorMessage: zipError } = useField(
      "zip",
      validateZipCode
    );
    const { value: phoneNo, errorMessage: phoneNoError } = useField(
      "phoneNo",
      validatePhone
    );
    const { value: email, errorMessage: emailError } = useField(
      "email",
      validateEmail
    );
    const {
      value: additionalLocationInfo,
      errorMessage: additionalLocationInfoError,
    } = useField("additionalLocationInfo", max500Chars);
    const {
      value: handPiledVegetation,
      errorMessage: handPiledVegetationError,
    } = useField("handPiledVegetation");
    const {
      value: existingSmallGardenSpot,
      errorMessage: existingSmallGardenSpotError,
    } = useField("existingSmallGardenSpot");
    return {
      firstName,
      lastName,
      address,
      city,
      zip,
      phoneNo,
      email,
      additionalLocationInfo,
      handPiledVegetation,
      existingSmallGardenSpot,
      firstNameError,
      lastNameError,
      addressError,
      cityError,
      zipError,
      phoneNoError,
      emailError,
      additionalLocationInfoError,
      handPiledVegetationError,
      existingSmallGardenSpotError,
      onSubmit,
      showErrors,
    };
  },
  mounted() {
    // we need to wait for the store to get crap outta storage.
    setTimeout(() => {
      if (this.getSavedFormData) {
        this.firstName = this.getSavedFormData.firstName;
        this.lastName = this.getSavedFormData.lastName;
        this.address = this.getSavedFormData.address;
        this.city = this.getSavedFormData.city;
        this.zip = this.getSavedFormData.zip;
        this.phoneNo = this.getSavedFormData.phoneNo;
        this.email = this.getSavedFormData.email;
        this.additionalLocationInfo =
          this.getSavedFormData.additionalLocationInfo;
        this.handPiledVegetation = this.getSavedFormData.handPiledVegetation;
        this.existingSmallGardenSpot =
          this.getSavedFormData.existingSmallGardenSpot;
      }
    }, 100);
  },
  computed: {
    getSavedFormData(): any {
      return this.$store.state.formData;
    },
    errorText(): boolean {
      if (
        this.showErrors &&
        (this.firstNameError ||
          this.lastNameError ||
          this.addressError ||
          this.cityError ||
          this.zipError ||
          this.phoneNoError ||
          this.emailError ||
          this.additionalLocationInfoError ||
          this.handPiledVegetationError ||
          this.existingSmallGardenSpotError)
      ) {
        return true;
      }
      return false;
    },
  },
  methods: {
    saveChangesLocally(key: string, value: any) {
      this.$store.dispatch("setFormData", { [key]: value });
    },
    formatPhone(fon: any) {
      this.phoneNo = formatPhoneNumber(fon.detail.value);
    },
    formatZip(zip: any) {
      this.zip = this.zipHip(zip.detail.value).slice(0,5)
    },
    zipHip(zip: string) {
      return ("" + zip).replace(/\D/g, "");
    }
  },
});
