
import { IonContent, IonPage, IonFooter } from "@ionic/vue";
import { defineComponent } from "vue";
import LogoHeader from "../components/LogoHeader.vue";
import CheckExistingList from "../components/permit/CheckExistingList.vue";
import GbpFooter from "@/components/Footer.vue";

export default defineComponent({
  name: "CheckExistingPage",
  components: {
    IonContent,
    IonFooter,
    IonPage,
    LogoHeader,
    CheckExistingList,
    GbpFooter
  },
  async mounted() {
    this.$store.dispatch("initFromStorage"); // dont you shouldnt be here if not.
  },
});
