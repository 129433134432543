<template>
  <ion-grid>
    <ion-row>
      <ion-col size-md="2" size="auto"> </ion-col>
      <ion-col size-md="8" class="ion-text-start ion-padding-horizontal">
        <h2 class="ion-no-margin">
          {{$t("review.ordinanceForm1")}} <strong v-if="selectedCounty">{{ selectedCounty.name }}</strong>{{$t("review.ordinanceForm2")}}
        </h2>
      </ion-col>
      <ion-col size-md="2" size="auto"> </ion-col>
    </ion-row>
      <ion-row v-if="selectedCountyOrdinance" class="ion-padding-top">
        <ion-col size-md="2" size="auto"> </ion-col>
        <ion-col size-md="8" class="ion-text-start ion-padding-horizontal ion-padding-top">
          <div class ="ion-padding" style="overflow-y: scroll; height: 300px; background: #FFFFFF; border: 1px solid #d7d8da"> 
            <p v-for="section in selectedCountyOrdinance.sections" :key="section">{{section}}</p>
          </div>
        </ion-col>
        <ion-col size-md="2" size="auto"> </ion-col>
      </ion-row>
    <ion-row class="ion-padding">
      <ion-col size-md="2" size="auto"> </ion-col>
      <ion-col size-md="8" class="flex ion-text-start ] ion-justify-content-center">
        <ion-checkbox
          v-model="formData.acceptedTerms"
          color="primary"
          @ionChange="saveFormData"
        ></ion-checkbox>
        <ion-label class="ion-padding-horizontal">
          {{$t("review.acceptTerms")}}
        </ion-label>
      </ion-col>
      <ion-col size-md="2" size="auto"> </ion-col>
    </ion-row>
  </ion-grid>
</template>
<script lang='ts'>
import { FirePermit } from "@/models/permit";
import {
  IonCol,
  IonGrid,
  IonLabel,
  IonRow,
  IonCheckbox,
} from "@ionic/vue";

import { defineComponent, reactive } from "vue";

export default defineComponent({
  name: "OrdinanceForm",
  components: {
    IonCheckbox,
    IonCol,
    IonGrid,
    IonLabel,
    IonRow,
  },
  setup() {
    const formData = reactive(new FirePermit());
    return {
      formData,
    };
  },
computed: {
    selectedCounty(): any {
      return this.$store.state.selectedCounty;
    },
    selectedCountyOrdinance(): any {
      return this.$store.state.selectedCountyOrdinance;
    },
    getSavedFormData(): any {
      return this.$store.state.formData;
    },
  },
  async mounted() {
    setTimeout(async () => {
      if (this.selectedCounty) {
        await this.$store.dispatch("getSelectedCountyOrdinance", this.selectedCounty.id);
      }
      if (this.getSavedFormData) {
        this.formData = Object.assign(this.formData, this.getSavedFormData);
      }
    }, 100);
  },
  methods: {
    saveFormData() {
      this.$store.dispatch("setFormDataNoSave", this.formData);
    },
  },
});
</script>
