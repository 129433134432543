
import { defineComponent } from 'vue'
  import { IonText,
} from "@ionic/vue";
export default defineComponent({
    name: 'InputError',
    components: {
        IonText,
    },
    props: {
        show: {
            type: Boolean,
            default: false
        },
        msg: {
            type: String,
            default:  "\u0000"
        }
    }
})
