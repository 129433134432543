
import UserSettings from "@/components/settings/UserSettings.vue";
import GbpFooter from "@/components/Footer.vue";
import {
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonPage,
  IonRow,
} from "@ionic/vue";
import { defineComponent } from "vue";
export default defineComponent({
  name: "SettingsPage",
  components: {
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonPage,
    IonRow,
    UserSettings,
    GbpFooter
  },
  mounted() {
    this.$store.dispatch("initFromStorage");
  }
});
