<template>
  <ion-grid>
    <ion-row class="ion-padding" />
    <ion-row>
      <ion-col size-lg="2" size="auto"> </ion-col>
      <ion-col size-lg="8" class="ion-text-start ion-padding-horizontal">
        <h3 class="ion-no-margin">
          {{ $t("permit.generalInfoHeading") }}
        </h3>
      </ion-col>
      <ion-col size-lg="2" size="auto"> </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size-lg="2"> </ion-col>
      <ion-col size-lg="8" size="12">
        <ion-list color="light" class="ion-no-padding">
          <ion-item button color="light" @click="openModal">
            <ion-label>{{ $t("apply.selectCounty") }}</ion-label>
            <div v-if="selectedCounty" style="width: 30%; text-align: right">
              {{ selectedCounty.name }}
            </div>
            <div style="opacity: 0.4" v-if="!selectedCounty">
              {{ $t("apply.selectCountyPlaceholder") }}
            </div>
            <ion-icon
              style="opacity: 0.4"
              :icon="caretDownOutline"
              size="small"
              slot="end"
            ></ion-icon>
          </ion-item>
          <!-- <ion-item button color="light" @click="openPermitTypeModal">
            <ion-label>{{ $t("apply.selectPermitType") }}</ion-label>
            <div
              v-if="formData.permitType"
              style="width: 30%; text-align: right"
            >
              {{ formData.permitType }}
            </div>
            <div style="opacity: 0.4" v-if="!formData.permitType">
              {{ $t("apply.selectPermitTypePlaceholder") }}
            </div>

            <ion-icon
              style="opacity: 0.4"
              :icon="caretDownOutline"
              size="small"
              slot="end"
            ></ion-icon>
          </ion-item> -->
        </ion-list>
      </ion-col>
      <ion-col size-lg="2"> </ion-col>
    </ion-row>
  </ion-grid>
</template>
<script lang='ts'>
import {
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonRow,
  modalController,
} from "@ionic/vue";
import { caretDownOutline } from "ionicons/icons";

import Modal from "./modal.vue";
import Modal2 from "./modal2.vue";
import { defineComponent, reactive } from "vue";
import { FirePermit } from "../../models/permit";

export default defineComponent({
  name: "ApplicationForm",
  components: {
    IonCol,
    IonGrid,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonRow,
  },
  setup() {
    const formData = reactive(new FirePermit());
    return {
      caretDownOutline,
      formData,
    };
  },
  mounted() {
    setTimeout(() => {
      if (this.getSavedFormData) {
        this.formData = reactive(
          Object.assign(this.formData, this.getSavedFormData)
        );
      }
      if (!this.formData.permitType) {
        this.formData.permitType = "Daily";
        this.saveFormData()
      }
    }, 100);
  },
  computed: {
    selectedCounty(): any {
      return this.$store.state.selectedCounty;
    },
    getSavedFormData(): any {
      return this.$store.state.formData;
    },
  },
  methods: {
    async openPermitTypeModal() {
      const modal = await modalController.create({
        component: Modal2,
      });
      await modal.present();

      const { data } = await modal.onDidDismiss();
      if (data) {
        this.formData.permitType = data;
        this.$store.dispatch("setFormData", {
          permitType: this.formData.permitType,
        });
      }
    },
    async openModal() {
      const modal = await modalController.create({
        component: Modal,
      });
      modal.present();
      const result = await modal.onDidDismiss();
      if (result && result.data) {
        this.$store.dispatch("saveFromListSelectedCounty", result.data);
      }
    },
    saveFormData() {
      this.$store.dispatch("setFormData", {
        permitType: this.formData.permitType,
      });
    },
  },
});
</script>
