<template>
  <ion-page>
    <ion-content class="ion-padding-bottom" color="light">
    <ion-grid>
        <ion-row>
          <ion-col size-lg="2" size="auto"> </ion-col>
          <ion-col size-lg="8" class="flex">
            <h1>Profile Settings</h1>
          </ion-col>
          <ion-col size-lg="2" size="auto"> </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size-lg="2" size="auto"> </ion-col>
          <ion-col size-lg="8" class="flex">
            <h4>Update your profile information and settings.</h4>
          </ion-col>
          <ion-col size-lg="2" size="auto"> </ion-col>
        </ion-row>
        <user-settings/>
    <ion-row>
    </ion-row>
    </ion-grid>
  </ion-content>
  <ion-footer>
    <gbp-footer/>
  </ion-footer>
  </ion-page>
</template>

<script lang="ts">
import UserSettings from "@/components/settings/UserSettings.vue";
import GbpFooter from "@/components/Footer.vue";
import {
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonPage,
  IonRow,
} from "@ionic/vue";
import { defineComponent } from "vue";
export default defineComponent({
  name: "SettingsPage",
  components: {
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonPage,
    IonRow,
    UserSettings,
    GbpFooter
  },
  mounted() {
    this.$store.dispatch("initFromStorage");
  }
});
</script>