
import {
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonFooter,
  IonItem,
  IonInput,
  IonTextarea,
  IonLabel,
  IonList,
  IonProgressBar,
  IonRow,
  IonText,
} from "@ionic/vue";
import {
  saveOutline,
  lockOpenOutline,
  lockClosedOutline,
} from "ionicons/icons";
import { useSnackbarPlugin } from "snackbar-vue";
import { defineComponent, reactive, ref } from "vue";

export default defineComponent({
  name: "CountyInfoForm",
  props: {
    initFormData: {
      type: Object,
      default: null,
    },
  },
  components: {
    IonButton,
    IonCol,
    IonGrid,
    IonItem,
    IonIcon,
    IonFooter,
    IonInput,
    IonProgressBar,
    IonTextarea,
    IonLabel,
    IonList,
    IonRow,
    IonText,
  },
  setup(props: any) {
    const snack = useSnackbarPlugin();
    const loaded = ref(false);
    const submitting = ref(false);
    const success = ref(false);
    const errorTxt = ref("");
    const edit = ref(false);
    const formData = reactive(Object.assign({}, props.initFormData));
    let t: any;
    return {
      snack,
      formData,
      t,
      loaded,
      submitting,
      success,
      errorTxt,
      edit,
      saveOutline,
      lockOpenOutline,
      lockClosedOutline,
    };
  },
  computed: {
    selectedCounty(): any {
      return this.$store.state.selectedCounty;
    },
    getSavedFormData(): any {
      return this.$store.state.formData;
    },
  },
  mounted() {
    // we need to wait for the store to get crap outta storage.
    setTimeout(() => {
      this.loaded = true;
    }, 300);
  },
  methods: {
    async checkForm() {
      this.submitting = true;
      const errorMsg = await this.$store.dispatch(
        "updateCounty",
        this.formData
      );
      if (errorMsg && errorMsg.length) {
        this.errorTxt = errorMsg;
        this.success = false;
      } else {
        this.errorTxt = this.$t("county.updateSuccess");
        this.success = true;
      }
      this.submitting = false;
      this.snack.show({
        position: "bottom",
        text: this.errorTxt ? this.errorTxt : this.$t("county.datesAdded"),
        time: 2500,
        close: true,
      });
      setTimeout(() => {
        this.errorTxt = "";
      }, 2500);
    },
  },
  watch: {
    initFormData(val) {
      this.formData = val;
    },
  },
});
