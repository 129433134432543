<template>
  <ion-grid>
    <ion-row />
    <ion-row>
      <ion-col size-lg="3"> </ion-col>
      <ion-col size-lg="6" size="12" class="light">
        <h5><strong>Payment Details</strong></h5>
        <div class="ion-padding-vertical">
          <ion-list>
            <ion-item>
              Card Type:
              <div slot="end" class="flex" style="justify-items: center">
                <payment-card-icon v-if="customer && customer.stripeCardType" :type="customer.stripeCardType" />
                <div v-if="customer && customer.stripeLastFour">&nbsp;&nbsp; card ending in {{customer.stripeLastFour}}</div>
                <div v-if="loaded && (!customer || !customer.stripeLastFour)">&nbsp;&nbsp; Press Update to add payment info</div>
              </div>
            </ion-item>
            <ion-item>
              Card Expiry:
              <div v-if="customer && customer.stripeExpMonth" slot="end">{{customer.stripeExpMonth}}/{{customer.stripeExpYear}}</div>
            </ion-item>
            <ion-item>
              Billing Interval:
              <div slot="end">Monthly</div>
            </ion-item>
            <ion-item lines="none">
              <div style="opacity: 0.7">Payments are securely processed each month by Stripe</div>
              <div slot="end">
                <img
                  width="128"
                  height="64"
                  src="https://cycleclub.nyc3.digitaloceanspaces.com/icons/stripe-black.svg"
                  class="auto align-self-center"
                  contain
                />
              </div>
            </ion-item>
            <ion-item lines="none">
              <ion-button slot="end" @click="openModal">UPDATE PAYMENT INFO </ion-button>
            </ion-item>
          </ion-list>
        </div>
      </ion-col>
      <ion-col size-lg="3"> </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size-lg="3"> </ion-col>
      <ion-col size-lg="6" size="12">
        <h5><strong>Latest Invoices</strong></h5>
        <div class="ion-padding-vertical">
          <ion-list>
            <ion-item>
            <h2 v-show="loaded" class="ion-text-center w100">No invoices found.</h2>
            </ion-item>


            <!-- <ion-item text-wrap>
              <ion-label>
                <h2>Invoice #21-1231</h2>
                <p>May 1/21 - May 31/21</p>
              </ion-label>
              <ion-text color="primary" slot="end"
                ><a style="text-decoration: underline">DOWNLOAD</a></ion-text
              >
            </ion-item>
            <ion-item text-wrap>
              <ion-label>
                <h2>Invoice #21-1230</h2>
                <p>Apr 1/21 - Apr 30/21</p>
              </ion-label>
              <ion-text color="primary" slot="end"
                ><a style="text-decoration: underline">DOWNLOAD</a></ion-text
              >
            </ion-item> -->
          </ion-list>
        </div>
      </ion-col>
      <ion-col size-lg="2"> </ion-col>
    </ion-row>
  </ion-grid>
</template>
<script lang='ts'>
import {
  IonButton,
  IonCol,
  IonGrid,
  IonLabel,
  IonItem,
  IonText,
  IonList,
  IonRow,
  modalController
} from "@ionic/vue";
import {
  saveOutline,
  lockOpenOutline,
  lockClosedOutline,
} from "ionicons/icons";

import { defineComponent, reactive, ref } from "vue";
import PaymentCardIcon from "./CardIcon.vue";
import UpdateBillingModal from "./UpdateBillingModal.vue";

export default defineComponent({
  name: "BillingDetails",
  props: {
    initFormData: {
      type: Object,
      default: null,
    },
  },
  components: {
    IonButton,
    IonCol,
    IonGrid,
    IonItem,
    // IonLabel,
    IonList,
    IonRow,
    // IonText,
    PaymentCardIcon,
  },
  setup(props: any) {
    const loaded = ref(false);
    const submitting = ref(false);
    const success = ref(false);
    const errorTxt = ref("");
    const edit = ref(false);
    const formData = reactive(Object.assign({}, props.initFormData));
    let t: any;
    return {
      formData,
      t,
      loaded,
      submitting,
      success,
      errorTxt,
      edit,
      saveOutline,
      lockOpenOutline,
      lockClosedOutline,
    };
  },
  computed: {
    selectedCounty(): any {
      return this.$store.state.selectedCounty;
    },
    customer(): any {
      return this.$store.state.myCustomer;
    },
  },
  mounted() {
    // we need to wait for the store to get crap outta storage.
    setTimeout(() => {
      this.loaded = true;
    }, 500);
  },
  methods: {
    async checkForm() {
      this.submitting = true;
      const errorMsg = await this.$store.dispatch(
        "updateCounty",
        this.formData
      );
      if (errorMsg && errorMsg.length) {
        this.errorTxt = errorMsg;
        this.success = false;
      } else {
        this.errorTxt = "Update Successful";
        this.success = true;
      }
      this.submitting = false;
      setTimeout(() => {
        this.errorTxt = "";
      }, 2500);
    },
    async openModal() {
      const modal = await modalController.create({
        component: UpdateBillingModal,
      });
      modal.present();
    },
  },
  watch: {
    initFormData(val) {
      this.formData = val;
    },
  },
});
</script>
