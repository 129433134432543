<template>
  <ion-row v-if="weatherAlerts">
    <ion-col size-lg="1"> </ion-col>
    <ion-col size-lg="10" size="12">
      <div>{{$t("ordinance.lastUpdated")}} {{ weatherAlerts.updated ? readableDate(weatherAlerts.updated.seconds) : '' }}</div>
    </ion-col>
    <ion-col size-lg="1"> </ion-col>
  </ion-row>
  <ion-row v-if="(!weatherAlerts || weatherAlerts.features.length == 0) && loaded">
    <ion-col size-lg="1"> </ion-col>
    <ion-col size-lg="10" size="12">
            <div class="col-sm-12 ion-center-text flex o-7 ion-padding-top" style="flex-direction: column;">
              <div class="auto">
                <ion-icon class="o-7" style="font-size: 72px;"
                :icon="informationCircleOutline">
                </ion-icon>
              </div>
              <div class="auto">
                <h4>{{errorMsg ? errorMsg : $t("dashboard.noCurrentAlerts") }}</h4>
              </div>
            </div>
    </ion-col>
    <ion-col size-lg="1"> </ion-col>
  </ion-row>
  <div v-if="weatherAlerts" class="ion-padding-top">
  <ion-row  class="ion-padding-bottom" v-for="w in weatherAlerts.features"
      :key="w.areaDesc">
    <ion-col size-lg="1"> </ion-col>
    <ion-col
      size-lg="10"
      size="12"
      class="ion-padding white"
    >
      <h4 class="mt-0 mb-1 ion-padding-horizontal">{{ w.NWSheadline }}</h4>
      <h6 class="mt-2 mb-0 o-7 ion-padding-horizontal">
        <i>
          {{ w.headline }}
        </i>
      </h6>
      <div class="ion-padding">
        {{ w.description }}
      </div>
      <div class="ion-padding pt-0 pb-4">
        {{ w.instruction }}
      </div>
      <h5 class="ion-padding-horizontal mt-0 mb-1">{{$t("dashboard.areasAffected")}}</h5>
      <div class="ion-padding">
        {{ w.areaDesc }}
      </div>
    </ion-col>
    <ion-col size-lg="1"> </ion-col>
  </ion-row>
  </div>
</template>
<script lang="ts">
import { dateMixin } from "@/utils/date.format";
import { IonRow, IonCol, IonIcon } from "@ionic/vue";
import { defineComponent, ref } from "vue";
import { informationCircleOutline } from "ionicons/icons"

export default defineComponent({
  name: "WeatherAlerts",
  mixins: [dateMixin],
  setup() {
    const loaded = ref(false)
    const errorMsg = ref('')
    return { loaded, errorMsg, informationCircleOutline}
  },
  components: {
    IonIcon,
    IonRow,
    IonCol,
  },
  computed: {
    weatherAlerts(): any {
      return this.$store.state.weatherAlerts;
    },
  },
  mounted() {
    this.load();
  },
  methods: {
   async load() {
      this.errorMsg = await this.$store.dispatch("getWeatherAlerts");
      setTimeout(() => {
        this.loaded = true
      }, 400) // empty state delay.
    },
  },
});
</script>