<template>
  <ion-grid>
    <ion-row class="ion-padding">
      <ion-col size-md="2" size="auto"> </ion-col>
      <ion-col size-md="8" class="ion-text-start">
        <h2 class="ion-no-margin">
          {{ $t("apply.existingPermits") }}
          {{
            getSavedFormData &&
            getSavedFormData.firstName &&
            getSavedFormData.lastName
              ? " for " +
                getSavedFormData.firstName +
                " " +
                getSavedFormData.lastName
              : ""
          }}
          {{ selectedCounty ? "in " + selectedCounty.name : "" }}
        </h2>
      </ion-col>
      <ion-col size-md="2" size="auto"> </ion-col>
    </ion-row>
    <ion-row class="ion-padding" />
    <ion-row>
      <ion-col size-md="2" size="auto"> </ion-col>
      <ion-col size-md="8" class="ion-text-start ion-padding-horizontal">
        <div
          v-if="!getLastPermit"
          class="flex white ion-padding"
          style="flex-direction: column"
        >
          <div>
            <ion-text class="ion-text-center" color="secondary">
              <h1>No active permits found.</h1>
              <h4>
                <strong
                  >You may apply for a new burn permit for {{ today }}
                </strong>
              </h4>
            </ion-text>
          </div>
        </div>
        <div
          v-if="getLastPermit"
          class="flex white ion-padding"
          style="flex-direction: column"
        >
          <div>
            <ion-text class="ion-text-center" color="secondary">
              <h1>
                You already have a permit for today.
              </h1>
            </ion-text>
          </div>
          <div style="max-width: 300px; margin: auto">
            <ion-text class="ion-text-center ion-padding-horizontald" color="secondary">
              <h6>
                Once this permit expires, you will be able to apply for new burn permit.
              </h6>
            </ion-text>
          </div>
          <div>
            <ion-text
              class="ion-text-center ion-padding-vertical"
              color="secondary"
            >
            <strong><h1 class="secondary">
              Permit # 
              {{
                getLastPermit.permitId
              }}
            </h1></strong>
            </ion-text>
            <ion-text class="ion-text-center"       color="secondary">
              <h4> {{getLastPermit.address + ', ' + getLastPermit.city + ', ' + getLastPermit.zip}} </h4>
            </ion-text>
          </div>
          <div>
            <ion-text
              class="ion-text-center ion-padding-vertical"
              color="secondary"
            >
            <h3>
              Expires
              {{
                addDayReadableDate(getLastPermit.submittedAt ? getLastPermit.submittedAt.seconds : 0)
              }}
            </h3>
            </ion-text>
          </div>
        </div>
      </ion-col>
      <ion-col size-md="2" size="auto"> </ion-col>
    </ion-row>
    <ion-row class="ion-padding"> </ion-row>
    <ion-row class="ion-padding-top">
      <ion-col size-md="4"> </ion-col>
      <ion-col size-md="4" class="flex">
        <ion-button
          color="primary"
          :disabled="getLastPermit"
          @click="$router.push('/review')"
          >{{ $t("apply.applyButton") }}</ion-button
        >
      </ion-col>
      <ion-col size-md="4"> </ion-col>
    </ion-row>
    <back-button-link route="/permit" />
  </ion-grid>
</template>
<script lang='ts'>
import {
  IonButton,
  IonCol,
  IonGrid,
  IonRow,
  IonText,
} from "@ionic/vue";
import { documentTextOutline } from "ionicons/icons";
import { defineComponent } from "vue";
import { dateMixin } from "../../utils/date.format";
import BackButtonLink from "./BackButtonLink.vue";

export default defineComponent({
  name: "CheckExistingList",
  mixins: [dateMixin],
  components: {
    IonButton,
    IonCol,
    IonGrid,
    IonRow,
    IonText,
    BackButtonLink,
  },
  computed: {
    selectedCounty(): any {
      return this.$store.state.selectedCounty;
    },
    getSavedFormData(): any {
      return this.$store.state.formData;
    },
    getLastPermit(): any {
      return this.$store.state.lastPermit;
    },
    today(): any {
      return this.todaysDate();
    },
  },
  setup() {
    return {
      documentTextOutline,
    };
  },
  async mounted() {
    setTimeout(async () => {
      if (this.selectedCounty) {
        await this.$store.dispatch("getLastPermit", this.selectedCounty.id);
      }
    }, 100);
  },
});
</script>
