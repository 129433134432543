<template>
  <ion-content class="ion-no-padding">
    <ion-list v-if="dateString && exDate">
      <ion-list-header>
        <h4>
          <strong>{{ dateString }}</strong>
        </h4>
      </ion-list-header>
      <p class="ion-padding-horizontal">{{ exDate.reason }}</p>
      <ion-grid>
        <ion-row class="ion-justify-end">
          <ion-col>
            <ion-button color="secondary" @click="confirmed">
              {{ $t("admin.removeBanOnDay") }}
            </ion-button>
          </ion-col>
          <ion-col>
            <ion-button  @click="close">
              {{ $t("admin.cancelButton") }}
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-list>
  </ion-content>
</template>

<script lang="ts">
import { ExcludedDate } from "@/models/excluded";
import {
  IonButton,
  IonContent,
  IonList,
  IonListHeader,
  IonRow,
  IonCol,
  IonGrid,
  popoverController,
} from "@ionic/vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "CalendarDayPopover",
  components: { IonButton, IonContent, IonList, IonListHeader, IonRow, IonCol, IonGrid },
  props: {
    exDate: {
      type: ExcludedDate,
      default: null,
    },
    dateString: {
      type: String,
      default: null,
    },
  },
  methods: {
    confirmed() {
      popoverController.dismiss(this.exDate);
    },
    close() {
      popoverController.dismiss();
    },
  },
});
</script>