
import {
  IonContent,
  IonPage,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
} from "@ionic/vue";
import { defineComponent } from "vue";
import { chevronBackOutline } from "ionicons/icons";
import { dateMixin } from "@/utils/date.format";
export default defineComponent({
  name: "OrdinancePreview",
  mixins: [dateMixin],
  components: {
    IonContent,
    IonGrid,
    IonRow,
    IonCol,
    IonPage,
    IonIcon,
  },
  // TODO
  // LOAD THE APPRIOPRIATE ORDINANCE BASED ON THE ID IN THE PATH URL.
  mounted() {
    setTimeout(() => {
      if (!this.selectedCounty || !!this.selectedCountyOrdinance) {
        if (this.$route.query && this.$route.query.id) {
          this.$store.dispatch("getSelectedCounty", this.$route.query.id);
        }
      }
    }, 500);
  },
  computed: {
    selectedCountyOrdinance(): any {
      return this.$store.state.selectedCountyOrdinance;
    },
    selectedCounty(): any {
      return this.$store.state.selectedCounty;
    },
  },
  setup() {
    return { chevronBackOutline };
  },
});
