<template>
  <ion-grid>
    <ion-row class="ion-padding-horizontal">
      <ion-col size-md="2" size="auto"> </ion-col>
      <ion-col
        size-md="8"
        class="ion-text-left flex ion-justify-content-between ion-align-items-center ion-padding-horizontal ion-padding-top"
        :style="{background: '#FFFFFF', opacity: disabled ? '0.5' : '1'}"
      >
        <ion-label>
          <strong>{{ $t("permit.countySelected") }}</strong>
        </ion-label>
      </ion-col>
      <ion-col size-md="2" size="auto"> </ion-col>
    </ion-row>
    <ion-row class="ion-padding-horizontal">
      <ion-col size-md="2" size="auto"> </ion-col>
      <ion-col
        size-md="8"
        class="ion-text-left flex ion-justify-content-between ion-align-items-center ion-padding-end"
        :style="{background: '#FFFFFF', opacity: disabled ? '0.5' : '1'}"
      >
        <h1 v-if="selectedCounty" class="ion-no-margin ion-padding-start">
          {{ selectedCounty.name }}
        </h1>
        <div class="auto"></div>
        <!-- <div class="ion-align-self-end">
          <ion-icon
            @click="openModal"
            class="text--accent pointer"
            :icon="createOutline"
            size="large"
          ></ion-icon>
        </div> -->
      </ion-col>
      <ion-col size-md="2" size="auto"> </ion-col>
    </ion-row>
    <ion-row class="ion-padding-horizontal">
      <ion-col size-md="2" size="auto"> </ion-col>
      <ion-col
        size-md="8"
        class="ion-text-left flex ion-justify-content-between ion-align-items-center ion-padding-horizontal ion-padding-top"
          :style="{background: '#FFFFFF', 'padding-bottom': '8px', opacity: disabled ? '0.5' : '1'}"
      >
        <ion-label>
          <strong>{{ $t("permit.burningConditions") }}</strong></ion-label
        >
      </ion-col>
      <ion-col size-md="2" size="auto"> </ion-col>
    </ion-row>
    <ion-row class="ion-padding-horizontal">
      <ion-col size-md="2" size="auto"> </ion-col>
      <ion-col
        size-md="8"
        class="ion-text-left flex ion-justify-content-between ion-align-items-center"
        :style="{background: '#FFFFFF', padding: '0px 16px 24px 16px', opacity: disabled ? '0.5' : '1'}"
      >
        <h4 v-if="selectedCounty" class="ion-no-margin ion-text-uppercase">
          {{ selectedCounty.additionalConditions }}
        </h4>
      </ion-col>
      <ion-col size-md="2" size="auto"> </ion-col>
    </ion-row>
    <ion-row class="ion-padding-horizontal" style="padding-top: 24px">
      <ion-col size-md="2" size="auto"> </ion-col>
      <ion-col
        size-md="8"
        class="ion-text-left flex ion-justify-content-between ion-align-items-center ion-padding-horizontal ion-padding-top"
        :style="{background: '#FFFFFF', opacity: disabled ? '0.5' : '1'}"
      >
        <ion-label>
          <strong>{{ $t("permit.permitTypeSelected") }}</strong>
        </ion-label>
      </ion-col>
      <ion-col size-md="2" size="auto"> </ion-col>
    </ion-row>
    <ion-row class="ion-padding-horizontal ion-padding-bottom">
      <ion-col size-md="2" size="auto"> </ion-col>
      <ion-col
        size-md="8"
        class="ion-text-left flex ion-justify-content-between ion-align-items-center"
        :style="{background: '#FFFFFF', padding: '0px 16px 24px 16px', opacity: disabled ? '0.5' : '1'}"
      >
        <h1 v-if="formData" class="ion-no-margin">
          {{ formData.permitType ?? "select a permit type" }}
        </h1>
        <div class="auto"></div>
        <div class="ion-align-self-end">
          <ion-icon
            @click="openModal2"
            class="text--accent pointer"
            :icon="createOutline"
            size="large"
          ></ion-icon>
        </div>
      </ion-col>
      <ion-col size-md="2" size="auto"> </ion-col>
    </ion-row>
  </ion-grid>
</template>

<script lang='ts'>
import { IonGrid, IonRow, IonCol, modalController } from "@ionic/vue";
import { defineComponent } from "vue";
import Modal from "./modal.vue";
import Modal2 from "./modal2.vue";
import { createOutline } from "ionicons/icons";

export default defineComponent({
  name: "AncillaryInfo",
  components: {
    IonCol,
    IonGrid,
    IonRow,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    return {
      createOutline,
    };
  },
  computed: {
    selectedCounty(): any {
      return this.$store.state.selectedCounty;
    },
    formData(): any {
      return this.$store.state.formData;
    },
  },
  methods: {
    async openModal() {
      if (!this.disabled) {
        const modal = await modalController.create({
          component: Modal,
        });
        modal.present();
        const result = await modal.onDidDismiss();
        if (result && result.data) {
          this.$store.dispatch("saveFromListSelectedCounty", result.data);
        }
      }
    },
    async openModal2() {
      if (!this.disabled) {
        const modal = await modalController.create({
          component: Modal2,
        });
        modal.present();
        const result = await modal.onDidDismiss();
        if (result && result.data) {
          this.$store.dispatch("setFormData", { permitType: result.data });
        }
      }
    },
  },
});
</script>
