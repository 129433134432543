
import {
  IonButton,
  IonCol,
  IonGrid,
  IonItem,
  IonInput,
  IonLabel,
  IonList,
  IonRow,
} from "@ionic/vue";
import {
  saveOutline,
  lockOpenOutline,
  lockClosedOutline,
} from "ionicons/icons";

import { defineComponent, reactive, ref } from "vue";

export default defineComponent({
  name: "UserSettings",
  props: {
    initFormData: {
      type: Object,
      default: null,
    },
  },
  components: {
    IonButton,
    IonCol,
    IonGrid,
    IonItem,
    IonInput,
    IonLabel,
    IonList,
    IonRow,
  },
  setup(props: any) {
    const loaded = ref(false);
    const submitting = ref(false);
    const success = ref(false);
    const errorTxt = ref('');
    const edit = ref(false);
    const formData = reactive(Object.assign({}, props.initFormData));
    let t: any;
    return {
      formData,
      t,
      loaded,
      submitting,
      success,
      errorTxt,
      edit,
      saveOutline,
      lockOpenOutline,
      lockClosedOutline,
    };
  },
  computed: {
    selectedCounty(): any {
      return this.$store.state.selectedCounty;
    },
    getSavedFormData(): any {
      return this.$store.state.formData;
    },
    getUser(): any {
      return this.$store.state.myUser
    }
  },
  mounted() {
    // we need to wait for the store to get crap outta storage.
    setTimeout(() => {
      this.loaded = true;
    }, 300);
  },
  methods: {
    async checkForm() {
      this.submitting = true;
      const errorMsg = '';
      if (errorMsg) {
        this.errorTxt = errorMsg;
        this.success = false;
      } else {
        this.errorTxt = 'Update Successful'
        this.success = true;
      }
      this.submitting = false;
      setTimeout(() => {
           this.errorTxt = "";
        }, 2500);
    },
  },
  watch: {
    initFormData(val) {
      this.formData = val
    }
  }
});
