<template>
  <ion-page>
    <ion-content class="ion-padding-top" color="light">
      <ion-grid style="min-height: 80vh">
        <ion-row>
          <ion-col size-lg="1"> </ion-col>
          <ion-col size-lg="10" size="12" @click="$store.dispatch('getMyCounties');">
            <h1>Your Regions</h1>
          </ion-col>
          <ion-col size-lg="1"> </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size-lg="1" > </ion-col>
          <ion-col size-lg="10" size="12" class="ion-text-start ion-padding-horizontal">
            <h4 class="ion-no-margin">
              Select the county or region you your are currently reviewing here. You will only be able to see
              the counties you have been granted access to.
            </h4>
            <h4 class="ion-no-margin ion-padding-bottom">
            If you would like to setup or access an additonal region, please <a href="mailto:info@georgiaburnpermits.com">contact us.</a>
            </h4>
          </ion-col>
          <ion-col size-lg="1"> </ion-col>
        </ion-row>
        <ion-row class="ion-padding-horizontal">
          <ion-col size-lg="3"> </ion-col>
          <ion-col size-lg="6" size="12">
            <ion-list>
              <ion-item
                v-for="c in myCounties"
                :key="c.name"
                class="pointer"
                :disabled="myCounties.length == 1"
                @click="setTempCountyAndEdit(c)"
              >
                <ion-label>
                  <h2>{{ c.name }}</h2>
                  <p>{{ c.additionalConditions }}</p>
                </ion-label>
                <ion-button slot="end" fill="clear">
                  <ion-icon
                    color="secondary"
                    :icon="chevronForwardOutline"
                    size="large"
                  ></ion-icon>
                </ion-button>
              </ion-item>
            </ion-list>
          </ion-col>
          <ion-col size-lg="3"> </ion-col>
        </ion-row>

        <ion-row> </ion-row>
      </ion-grid>
      <gbp-footer/>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import {
  IonButton,
  IonContent,
  IonPage,
  IonGrid,
  IonIcon,
  IonRow,
  IonCol,
  IonList,
  IonItem,
  IonLabel,
} from "@ionic/vue";
import { defineComponent } from "vue";
import { chevronForwardOutline } from "ionicons/icons";
import { County } from "@/models/county";
import GbpFooter from "@/components/Footer.vue";

export default defineComponent({
  name: "CountiesPage",
  components: {
    IonButton,
    IonContent,
    IonCol,
    IonPage,
    IonGrid,
    IonIcon,
    IonRow,
    IonList,
    IonItem,
    IonLabel,
    GbpFooter
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return { chevronForwardOutline };
  },
  computed: {
    myCounties(): any {
      return this.$store.state.myCounties;
    },
  },
  mounted() {
    if (!this.myCounties || !this.myCounties.length)
    this.$store.dispatch("getMyCounties");
  },
  methods: {
    async setTempCountyAndEdit(county: County) {
      await this.$store.dispatch("saveFromListSelectedCounty", county);
      this.$router.push("/admin");
    },
  },
});
</script>