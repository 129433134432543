
import { defineComponent } from "vue";
import {
  IonItem,
} from "@ionic/vue";

export default defineComponent({
  components: {
      IonItem
  },
  props: {
    caption: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
  },
});
