<template>
  <div id="stripecontainer" class="stripecontainer">
    <form id="payment-form">
      <div class="try-me">
        <label for="card-number" class="caption stripe-label">
          Card Number
        </label>
        <div id="card-number"></div>
      </div>

      <ion-row class="pt-4">
        <ion-col size="6">
          <div class="try-me">
            <label for="card-expiry" class="caption stripe-label">
              Card Expiry
            </label>
            <div id="card-expiry"></div>
          </div>
        </ion-col>
        <ion-col size="6">
          <div class="try-me">
            <label for="card-number" class="caption  stripe-label">
              Card CVC
            </label>
            <div id="card-cvc"></div>
          </div>
        </ion-col>
      </ion-row>
      <ion-row class="pt-4 ion-justify-content-space-between ion-align-items-center ion-padding-horizontal">
        <ion-col>
                <img
                  width="128"
                  height="64"
                  src="https://cycleclub.nyc3.digitaloceanspaces.com/icons/stripe-black.svg"
                  class="auto align-self-center pt-2"
                  contain
                />
        </ion-col>
        <ion-col size="auto">
      <!-- We'll put the error messages in this element -->
      <div id="card-number-errors" role="alert"></div>
      <ion-button
        :disabled="sending || !disable"
        type="submit"
        color="primary"
        >{{buttonText ? buttonText : 'ACTIVATE ACCOUNT'}}</ion-button
      >
        </ion-col>
      </ion-row>
    </form>
  </div>
</template>
<script lang='ts'>
// Firebase App (the core Firebase SDK) is always required and must be listed first
import firebase from "firebase/app";
// Add the Firebase products that you want to use
import "firebase/auth";
import {
  IonButton,
  IonCol,
  IonRow,
} from "@ionic/vue";
import { defineComponent } from "vue";
import { STRIPEPUBKEY } from './../../constants';
import { useSnackbarPlugin } from "snackbar-vue";

export default defineComponent({
  name: 'StripeBox',
  components: {
    IonButton,
    IonCol,
    IonRow
  },
  setup() {
    const snack = useSnackbarPlugin();
    return { snack }
  },
  emits: ['paymentMethod'],
  props: {
    sending: {
      type: Boolean,
      default: false
    },
    disable: {
      type: Boolean,
      default: false
    },
    nameOnCard: {
      type: String,
      default: ''
    },
    address: {
      type: String,
      default: ''
    },
    buttonText: {
      type: String,
      default: ''
    }
  },
  computed: {
    getUser(): any {
      return this.$store.state.myUser
    }
  },
  mounted() {
    if (window && window.Stripe) {
      const stripe = window.Stripe(STRIPEPUBKEY)
      const elements = stripe.elements()
      const fieldClasses = {
        focus: 'focus',
        empty: 'empty',
        invalid: 'invalid'
      }
      const fieldStyle = {
        base: {
          fontSize: '16px',
          fontSmoothing: 'antialiased',
          fontFamily: 'Roboto, Arial',
          iconColor: '#CFD7DF',
        }
      }
      // const card = elements.create('card', fieldOptions)
      // // Add an instance of the card Element into the `card-number` <div>
      // card.mount('#card-number')

      const cardNumber = elements.create('cardNumber', {
        style: fieldStyle,
        classes: fieldClasses,
        placeholder: 'XXXX XXXX XXXX XXXX'
      })
      cardNumber.mount('#card-number')

      const cardExpiry = elements.create('cardExpiry', {
        style: fieldStyle,
        classes: fieldClasses
      })
      cardExpiry.mount('#card-expiry')

      const cardCvc = elements.create('cardCvc', {
        style: fieldStyle,
        classes: fieldClasses
      })
      cardCvc.mount('#card-cvc')

      // get the form element.
      const stripecontainer = document.querySelector('.stripecontainer')
      const form = stripecontainer!.querySelector('#payment-form')
      if (!form || !stripecontainer) {
        return
      }
      form.addEventListener('submit', (e) => {
        e.preventDefault()

        // Trigger HTML5 validation UI on the form if any of the inputs fail
        // validation.
        let plainInputsValid = true
        Array.prototype.forEach.call(form.querySelectorAll('input'), function(
          input
        ) {
          if (input.checkValidity && !input.checkValidity()) {
            plainInputsValid = false
          }
        })
        if (!plainInputsValid) {
          this.triggerBrowserValidation(form)
          return
        }

        // Show a loading screen...
        stripecontainer.classList.add('submitting')

        // Disable all inputs.
        // disableInputs();

        if (!this.nameOnCard || !this.address) {
          this.$emit('paymentMethod', null)
        } else {
          stripe
            .createPaymentMethod({
              type: 'card',
              card: cardNumber, // card,
              billing_details: {
                name: this.nameOnCard,
                address: {
                  line1: this.address,
                  state: 'GA',
                  country: 'US'
                },
                email: this.getUser.email
              }
            })
            .then((result: any) => {
              if (result.error) {
                this.snack.show({
                  position: "bottom",
                  text: (result.error && result.error.message) ? result.error.message : result.error,
                  time: 2500,
                  close: true,
                });
              } else {
                // EMIT THE PAYMENT METHOD SO WE CAN CREATE SUBSCRIPTION
                this.$emit('paymentMethod', result.paymentMethod)
              }
            })
            .finally(() => {
              // Stop loading!
              stripecontainer.classList.remove('submitting')
              stripecontainer.classList.add('submitted')
            })
        }
        // Otherwise, un-disable inputs.
        // enableInputs()
      })
    } else {
      // console.log('no stripe!')
    }
  },
  methods: {
    triggerBrowserValidation(form: any) {
      // The only way to trigger HTML5 form validation UI is to fake a user submit
      // event.
      const submit = document.createElement('input')
      submit.type = 'submit'
      submit.style.display = 'none'
      form.appendChild(submit)
      submit.click()
      submit.remove()
    }
  }
});
</script>
<style scoped>
.try-me {
  background-color: rgba(255, 255, 255, 0.08);
  transition: background-color 0.3s ease;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.stripe-label {
  padding: 8px 4px 2px 12px;
  opacity: 0.7;
}

#card-number,
#card-expiry,
#card-cvc {
  /* background-color: rgba(255, 255, 255, 0.16); */
  transition: background-color 0.3s ease;
  border-bottom: 1px solid #cfd7df;
  padding: 4px 12px 8px 12px;
  border-radius: 4px;
  /* height: 48px; */
  
}
#card-number.StripeElement--focus,
#card-expiry.StripeElement--focus,
#card-cvc.StripeElement--focus {
  border-bottom: 1px solid white;
}
/* #card-number:hover,
#card-expiry:hover,
#card-cvc:hover, */
.try-me:hover {
  background-color: rgba(255, 255, 255, 0.16);
  transition: background-color 0.3s ease;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #f4f5f8 inset !important;
  box-shadow: 0 0 0 30px #f4f5f8 inset !important;
  padding: 0px !important;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px #f4f5f8 inset !important;
  -webkit-text-fill-color: black !important;
  box-shadow: 0 0 0 30px #f4f5f8 inset !important;
  padding: 0px !important;
}

</style>
