
import { defineComponent, ref } from "vue";
import {
  IonIcon,
  IonLabel,
  IonPage,
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonText
} from "@ionic/vue";
import { listOutline, cloudOutline,  } from "ionicons/icons";
import LogoHeader from "../../components/LogoHeader.vue";


export default defineComponent({
  name: "DashboardPage",
  components: {
    IonIcon,
    IonLabel,
    IonPage,
    IonTabBar,
    IonTabButton,
    IonTabs,
    IonText,
    LogoHeader
  },
  setup() {
    const selectedTab = ref('permits')
    const beforeTabChange = () => {
      // do something before tab change
    };
    const afterTabChange = () => {
      // do something after tab change
    };
    return {
      selectedTab,
      listOutline,
      
      cloudOutline,
      beforeTabChange,
      afterTabChange,
    };
  },
  mounted() {
    this.$store.dispatch("initFromStorage");
    if (this.$route.path.includes('permits')) {
      this.selectedTab = 'permits'
    } else if (this.$route.path.includes('counties')) {
      this.selectedTab = 'counties'
    }
    setTimeout(()=> {
      if (!this.customer) {
        this.$router.push('/')
      }
    }, 200)
  },
  computed: {
    selectedCounty(): any {
      return this.$store.state.selectedCounty;
    },
    customer(): any {
      return this.$store.state.myCustomer;
    },
  },
});
