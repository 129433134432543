
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonToolbar,
  modalController
} from "@ionic/vue";
import { defineComponent, ref} from "vue";
import { closeOutline } from "ionicons/icons";
import { TERMS } from "@/constants";

export default defineComponent({
  name: "Modal",
  setup() {
    const search = ref('')
    return { closeOutline, search };
  },
  components: {
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonToolbar
  },
  computed: {
    terms(): any {
      return TERMS
    }
  },
  methods: {
    closeMe() {
      modalController.dismiss()
    },
  }
});
