
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonList,
  IonIcon,
  IonItem,
  IonLabel,
  IonToolbar,
  modalController
} from "@ionic/vue";
import { defineComponent } from "vue";
import { closeOutline } from "ionicons/icons";

export default defineComponent({
  name: "Modal2",
  setup() {
    return { closeOutline };
  },
  components: {
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonList,
    IonItem,
    IonLabel,
    IonToolbar
  },
  methods: {
    closeMe(c: string) {
      modalController.dismiss(c)
    }
  }
});
