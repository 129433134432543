
import { defineComponent } from "vue";
import { IonGrid, IonRow, IonCol } from "@ionic/vue";
import { PRIVACY_POLICY_LINK, TERMS_LINK, CONTACT_LINK } from "@/constants";

export default defineComponent({
  name: "GbpFooter",
  components: {
    IonGrid,
    IonRow,
    IonCol,
  },
  computed: {
    termsLink() {
      return TERMS_LINK;
    },
    privacyLink() {
      return PRIVACY_POLICY_LINK;
    },
    contactLink() {
      return CONTACT_LINK;
    }

  }
});
