
import {
  IonButton,
  IonContent,
  IonGrid,
  IonCol,
  IonRow,
  IonPage,
  IonText,
} from "@ionic/vue";
import { defineComponent } from "vue";
import LogoHeader from "../components/LogoHeader.vue";
import { dateMixin } from "./../utils/date.format";
// import BackButtonLink from './../components/BackButtonLink.vue';
import { useSnackbarPlugin } from "snackbar-vue";

export default defineComponent({
  name: "CompletePage",
  mixins: [dateMixin],
  components: {
    // BackButtonLink,
    IonButton,
    IonCol,
    IonRow,
    IonGrid,
    IonContent,
    IonPage,
    IonText,
    LogoHeader,
  },
  setup() {
    const snack = useSnackbarPlugin();
    return { snack }
  },
  computed: {
    getcompletedPermit(): any {
      return this.$store.state.completedPermit;
    },
    selectedCounty(): any {
      return this.$store.state.selectedCounty;
    },
    selectedCountyOrdinance(): any {
      return this.$store.state.selectedCountyOrdinance;
    },
  },
  mounted() {
    this.$store.dispatch("initFromStorage");
    setTimeout(() => {
    if (this.getcompletedPermit) {
      this.snack.show({
        position: "bottom",
        text: this.errorTxt ? this.errorTxt : "Permit created successfully",
        time: 2500,
        close: true,
      });
    } else {
      this.$router.push('/check')
    }
    }, 250)

  },
});
