
import { IonGrid, IonRow, IonCol, IonText } from "@ionic/vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "AncillaryInfo",
  components: {
    IonCol,
    IonGrid,
    IonRow,
    IonText,
  },
});
