<template>
  <ion-grid>
    <ion-row>
      <ion-col size-lg="1" size="auto"> </ion-col>
      <ion-col size-lg="10" class="ion-text-start">
        <h1>{{ $t("dashboard.title") }}</h1>
      </ion-col>
      <ion-col size-lg="1" size="auto"> </ion-col>
    </ion-row>
    <ion-row class="ion-padding-horizontal">
      <ion-col size-lg="1" size="auto"> </ion-col>
      <ion-col size-lg="10">
        <ion-grid>
          <ion-row
            class="ion-justify-content-evenly"
            v-if="selectedCounty"
          >
            <ion-col size-lg="auto" size="12">
              <dashboard-card :label="$t('dashboard.cardThisWeek')" :value="selectedCounty.permitsThisWeek" />
            </ion-col>
            <ion-col size-lg="auto" size="12">
              <dashboard-card :label="$t('dashboard.cardThisMonth')" :value="selectedCounty.permitsThisMonth" />
            </ion-col>
            <ion-col size-lg="auto" size="12">
              <dashboard-card :label="$t('dashboard.cardPermitsTotal')" :value="selectedCounty.permitsTotal" />
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-col>
      <ion-col size-lg="1" size="auto"> </ion-col>
    </ion-row>
  </ion-grid>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { IonGrid, IonRow, IonCol } from "@ionic/vue";
import DashboardCard from "./DashboardCard.vue";

export default defineComponent({
  name: "PermitDashboard",
  components: {
    IonGrid,
    IonRow,
    IonCol,
    DashboardCard,
  },
  computed: {
    selectedCounty(): any {
      return this.$store.state.selectedCounty;
    },
  }
});
</script>
