<template>
  <ion-page>
    <ion-tabs
      @ionTabsWillChange="beforeTabChange"
      @ionTabsDidChange="afterTabChange"
    >
      <div>
        <logo-header />
      </div>
      <div class="ion-text-center ion-padding-horizontal">
        <ion-text color="primary">
          <h4 class="mt-0 mb-2">
            {{ selectedCounty ? selectedCounty.name : "No Selected County" }}
          </h4>
        </ion-text>
      </div>
      <ion-tab-bar :selectedTab="selectedTab" slot="top">
        <ion-tab-button tab="details" href="/admin/details">
          <ion-icon :icon="informationCircleOutline"></ion-icon>
          <ion-label>{{ $t("admin.contactInfo") }}</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="calendar" href="/admin/calendar">
          <ion-icon :icon="calendarOutline"></ion-icon>
          <ion-label>{{ $t("admin.restrictedDates") }}</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="ordinance" href="/admin/ordinance">
          <ion-icon :icon="readerOutline"></ion-icon>
          <ion-label>{{ $t("admin.countyOrdinance") }}</ion-label>
        </ion-tab-button>
      </ion-tab-bar>
    </ion-tabs>
  </ion-page>
</template>

<script lang="ts">
import {
  IonTabs,
  IonTabBar,
  IonTabButton,
  IonIcon,
  IonLabel,
  IonPage,
  IonText,
} from "@ionic/vue";
import { ref, defineComponent } from "vue";
import { calendarOutline, informationCircleOutline, readerOutline } from "ionicons/icons";
import LogoHeader from "../../components/LogoHeader.vue";

export default defineComponent({
  name: "CountyAdmin",
  components: {
    IonTabs,
    IonTabBar,
    IonTabButton,
    IonIcon,
    IonLabel,
    IonPage,
    IonText,
    LogoHeader,
  },
  setup() {
    const selectedTab = ref('permits')
    const beforeTabChange = () => {
      // do something before tab change
    };
    const afterTabChange = () => {
      // do something after tab change
    };
    return {
      selectedTab,
      calendarOutline,
      informationCircleOutline,
      readerOutline,
      beforeTabChange,
      afterTabChange,
    };
  },
  mounted() {
    this.$store.dispatch("initFromStorage");
    if (this.$route.path.includes('details')) {
      this.selectedTab = 'details'
    } else if (this.$route.path.includes('calendar')) {
      this.selectedTab = 'calendar'
    } else if (this.$route.path.includes('ordinance')) {
      this.selectedTab = 'ordinance'
    }
  },
  computed: {
    selectedCounty(): any {
      return this.$store.state.selectedCounty;
    },
  },
});
</script>