import { DateTime } from 'luxon'

// Date Mixin
export const dateMixin = {
  methods: {
    readableShortDate (seconds?: number): string {
      return seconds ? DateTime.fromSeconds(seconds).toLocaleString(DateTime.DATE_MED) : '' // Oct 14, 1983
    },
    readableDate (seconds?: number): string {
      return seconds ? DateTime.fromSeconds(seconds).toLocaleString(DateTime.DATETIME_FULL) : '' //  	October 14, 1983, 1:30 PM EDT
    },
    addDayReadableDate (seconds?: number): string {
      return seconds ? DateTime.fromSeconds(seconds).plus({ days: 1 }).toLocaleString(DateTime.DATETIME_FULL) : '' // 	October 15, 1983, 1:30 PM EDT
    },
    isSameAsToday(seconds: number) {
      return DateTime.now().hasSame(DateTime.fromSeconds(seconds), 'day');
    },
    todaysDate() {
      return DateTime.now().toLocaleString(DateTime.DATE_MED)
    },
    yesterday8pmDate() {
      return DateTime.now().minus({days: 1}).set({hour: 20, minute: 0, second: 0, millisecond: 0}).toJSDate()
    }
  }
}
