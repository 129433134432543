<template>
  <ion-grid>
    <ion-row>
      <ion-col size-lg="2" size="auto"> </ion-col>
      <ion-col size-lg="8">
        <ion-text class="ion-text-center ion-padding">
          <h1 class="ion-text-center">
            {{ $t("apply.applyToday") }}
          </h1>
        </ion-text>
      </ion-col>
      <ion-col size-lg="2" size="auto"> </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size-lg="2" size="auto"> </ion-col>
      <ion-col size-lg="8">
        <ion-text class="ion-text-start ion-padding-start">
          <h3 class="mt-0" style="margin-left: 8px">
            {{ $t("apply.whyPermit") }}
          </h3>
        </ion-text>
      </ion-col>
      <ion-col size-lg="2" size="auto"> </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size-lg="2" size="auto"> </ion-col>
      <ion-col size-lg="8" class="flex  ion-padding-horizontal">
        <ion-text class="ion-text-start">
          {{ $t("apply.permitDescription") }}
        </ion-text>
      </ion-col>
      <ion-col size-lg="2" size="auto"> </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size-lg="2" size="auto"> </ion-col>
      <ion-col size-lg="8">
        <ion-text class="ion-text-start ion-padding-start">
          <h3 class="ion-padding-start mt-0">
            {{ $t("apply.whoPermit") }}
          </h3>
        </ion-text>
      </ion-col>
      <ion-col size-lg="2" size="auto"> </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size-lg="2" size="auto"> </ion-col>
      <ion-col size-lg="8" class="flex">
        <ion-text class="ion-text-start ion-padding-horizontal">
          {{ $t("apply.permitsWho") }}
        </ion-text>
      </ion-col>
      <ion-col size-lg="2" size="auto"> </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size-lg="2" size="auto"> </ion-col>
      <ion-col size-lg="8">
        <ion-text class="ion-text-start ion-padding-start">
          <h3 class="ion-padding-start mt-0 mb-0">
            {{ $t("apply.howPermit") }}
          </h3>
        </ion-text>
      </ion-col>
      <ion-col size-lg="2" size="auto"> </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size-lg="2" size="auto"> </ion-col>
      <ion-col size-lg="8" class="flex">
        <ion-text class="ion-text-start  ion-padding-horizontal pt-3">
          {{ $t("apply.permitDesc2Part1") }}
          <ion-text color="danger">{{ $t("apply.permitDesc2Part2") }}</ion-text>
          {{ $t("apply.permitDesc2Part3") }}
        </ion-text>
      </ion-col>
      <ion-col size-lg="2" size="auto"> </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size-lg="2" size="auto"> </ion-col>
      <ion-col size-lg="8">
        <ion-text class="ion-text-start ion-padding-start">
          <h3 class="ion-padding-start mt-0 mb-0">
            {{ $t("apply.whatPermit") }}
          </h3>
        </ion-text>
      </ion-col>
      <ion-col size-lg="2" size="auto"> </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size-lg="2" size="auto"> </ion-col>
      <ion-col size-lg="8" class="flex">
        <ion-text class="ion-text-start ion-padding">
            {{ $t("apply.youShouldNotBurn") }}
        </ion-text>
      </ion-col>
      <ion-col size-lg="2" size="auto"> </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size-lg="2" size="auto"> </ion-col>
      <ion-col size-lg="8" class="flex">
        <ion-text
          class="ion-text-center ion-padding-horizontal text--danger auto"
        >
          <h4>{{ $t("apply.redTextPart1") }}</h4>
        </ion-text>
      </ion-col>
      <ion-col size-lg="2" size="auto"> </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size-lg="2" size="auto"> </ion-col>
      <ion-col size-lg="8" class="flex">
        <ion-text class="ion-text-center ion-padding-horizontal text--danger">
          <h6>{{ $t("apply.redTextPart2") }}</h6>
        </ion-text>
      </ion-col>
      <ion-col size-lg="2" size="auto"> </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size-lg="2" size="auto"> </ion-col>
      <ion-col size-lg="8" class="flex">
        <ion-text class="ion-text-center ion-padding-horizontal text--danger">
          <h6>
            {{ $t("apply.redTextPart3") }}
          </h6>
        </ion-text>
      </ion-col>
      <ion-col size-lg="2" size="auto"> </ion-col>
    </ion-row>
  </ion-grid>
</template>

<script lang='ts'>
import { IonGrid, IonRow, IonCol, IonText } from "@ionic/vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "AncillaryInfo",
  components: {
    IonCol,
    IonGrid,
    IonRow,
    IonText,
  },
});
</script>
