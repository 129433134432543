
import {
  IonContent,
  IonPage,
  IonGrid,
  IonRow,
  IonCol,
} from "@ionic/vue";
import { defineComponent } from "vue";
import GbpFooter from "@/components/Footer.vue";
import WeatherAlerts from "@/components/admin/WeatherAlerts.vue";

export default defineComponent({
  name: "CountiesPage",
  components: {
    IonContent,
    IonCol,
    IonPage,
    IonGrid,
    IonRow,
    GbpFooter,
    WeatherAlerts,
  },
});
