
import LoginForm from "@/components/admin/LoginForm.vue";
import { IonContent, IonFooter, IonPage } from "@ionic/vue";
import { defineComponent, ref } from "vue";
import LogoHeader from "../components/LogoHeader.vue";
import GbpFooter from "@/components/Footer.vue";

export default defineComponent({
  name: "LoginPage",
  components: {
    IonContent,
    IonFooter,
    IonPage,
    LogoHeader,
    LoginForm,
    GbpFooter,
  },
  setup() {
    const loading = ref(false);
    const errorTxt = ref("");
    return {
      loading,
      errorTxt,
    };
  },
  mounted() {
    this.$store.dispatch("initFromStorage");
  },
  methods: {
    async onSubmit(values: { username: string; password: string }) {
      this.loading = true;
      const errorMsg = await this.$store.dispatch("login", {
        email: values.username,
        password: values.password,
      });
      if (errorMsg && errorMsg.length) {
        this.errorTxt = errorMsg;
        setTimeout(() => {
          this.errorTxt = "";
          this.loading = false;
        }, 3000);
      } else {
        setTimeout(() => {
          this.loading = false;
          this.$router.push("/dashboard");
        }, 1000);
      }
    },
  },
});
