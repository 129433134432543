<template>
  <ion-page>
    <ion-content>
      <ion-grid class="ion-padding-top">
        <ion-row>
          <ion-col>
            <ion-icon
              style="padding-left: 8px"
              :icon="chevronBackOutline"
              button
              class="pointer"
              @click="$router.go(-1)"
            />
          </ion-col>
        </ion-row>
      </ion-grid>
      <ion-grid v-if="selectedCountyOrdinance" style="margin: 0 48px 24px 48px">
        <ion-row>
          <ion-col size="12">
            <h1 style="font-size: 64px">{{ selectedCounty.name }}{{ $t("ordinance.burnOrdinance") }}</h1>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="12">
            <h4 style="margin: 0">
              <i style="opacity: 0.7"
                >{{ $t("ordinance.lastUpdated") }}:&nbsp;
                {{
                  readableDate(selectedCountyOrdinance.lastUpdated.seconds)
                }}</i
              >
            </h4>
          </ion-col>
        </ion-row>
        <ion-row
          v-for="(o, index) in selectedCountyOrdinance.sections"
          :key="o"
        >
          <ion-col size="12">
            <h2 v-if="index == 0">{{ o }}</h2>
            <h6 v-else>{{ o }}</h6>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import {
  IonContent,
  IonPage,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
} from "@ionic/vue";
import { defineComponent } from "vue";
import { chevronBackOutline } from "ionicons/icons";
import { dateMixin } from "@/utils/date.format";
export default defineComponent({
  name: "OrdinancePreview",
  mixins: [dateMixin],
  components: {
    IonContent,
    IonGrid,
    IonRow,
    IonCol,
    IonPage,
    IonIcon,
  },
  // TODO
  // LOAD THE APPRIOPRIATE ORDINANCE BASED ON THE ID IN THE PATH URL.
  mounted() {
    setTimeout(() => {
      if (!this.selectedCounty || !!this.selectedCountyOrdinance) {
        if (this.$route.query && this.$route.query.id) {
          this.$store.dispatch("getSelectedCounty", this.$route.query.id);
        }
      }
    }, 500);
  },
  computed: {
    selectedCountyOrdinance(): any {
      return this.$store.state.selectedCountyOrdinance;
    },
    selectedCounty(): any {
      return this.$store.state.selectedCounty;
    },
  },
  setup() {
    return { chevronBackOutline };
  },
});
</script>
