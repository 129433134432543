import { createStore } from 'vuex'
import { FirebaseService } from './services/firebase.service';
import { FirestoreDate } from './models/permit';

const firebaseService = new FirebaseService();


const getDefaultState = () => {
  return {
    // TODO separate selectedCounty and adminSelectedCounty. and dates.
    initComplete: false,
    counties: [] as any,
    selectedCounty: null as any,
    selectedCountyOrdinance: null as any,
    selectedCountyExcludedDates: [] as any,
    canApply: false,
    formData: null as any, // TODO isn't this that GBPSavedData class
    completedPermit: null as any,
    lastPermit: null as any,
    // ADMIN RELATED
    myUser: null as any,
    myCounties: [] as any,
    myCustomer: null as any,
    permits: [] as any,
    lastDoc: null as any,
    firstDoc: null as any,
    pageSize: 2, // TODO change this page size.
    weatherAlerts: null as any, // {features, title, updated}
    scrollTop: 0
  }
}

export const store = createStore({
  state: getDefaultState(),
  actions: {
    async resetStorage() {
      await localStorage.clear();
    },
    clearAll({ commit, dispatch }) {
      dispatch('resetStorage')
      commit('resetStore')
    },

    // SET AND SAVE VALUES
    async saveFromListSelectedCounty({ commit, dispatch }, county) {
      await commit('saveSelectedCounty', county)
      // if county changes, load related info.
      dispatch('getSelectedCountyOrdinance') // maybe don't need this right away?
      dispatch('getSelectedCountyExcludedDates')
    },
    async setFormData({ commit }, formData) {
      commit('setFormData', formData)
    },
    async setFormDataNoSave({ commit }, formData) {
      commit('setFormData', formData)
      // no save, used for values like accept terms.
    },

    // INIT METHOD RAN ON START UP.

    async initFromStorage({ state, commit, dispatch }) {
      if (!state.initComplete) {
        console.log('getotttastorage')
        commit('completeInit')
        try {
          let county: any = await localStorage.getItem('gbpCounty')
          const formData = await localStorage.getItem('gbpFormData')
          if (county) {
            county = JSON.parse(county)
            await dispatch('getSelectedCounty', county.id)
            dispatch('getSelectedCountyOrdinance')
            dispatch('getSelectedCountyExcludedDates')
          }
          if (formData) {
            commit('setFormData', JSON.parse(formData))
          }

          // also listen for user changes.
          firebaseService.onAuthStateChange((firebaseUser: any) => {
            // console can be null if logged out.
            commit('setMyUser', firebaseUser)
            dispatch('loadCustomer', firebaseUser)
            firebaseUser ? dispatch("getMyCounties") : commit('setMyCounties', [])
          });

          // listen for counties
          // TODO should we listen for county changes like this?
          // 
          // firebaseService.getDb().collection("counties").onSnapshot((querySnapshot) => {
          //   commit('setCounties', querySnapshot);
          // });

        } catch (err) {
          console.log(`could not init from storage ${err}`)
        }
      }
    },

    // PERMIT RELATED METHODS

    async getCounties({ commit } , search: string) {
      const snapshot = await firebaseService.searchCounties(search);
      if (snapshot) {
        commit('setCounties', snapshot)
      }
    },
    async getLastPermit({ state, commit }) {

      if (state.selectedCounty && state.formData && state.formData!.email) {
        // typescript null why o why.
        const snapshot = await firebaseService.getMyPermits(state.selectedCounty!.id, 'email', state.formData!.email);
        const snapshot2 = await firebaseService.getMyPermits(state.selectedCounty!.id, 'phoneNo', state.formData!.phoneNo);
          commit('setLastPermits', { snapshot, snapshot2 })
      }
    },

   async getSelectedCounty({ commit }, countyId) {
     const snapshot = await firebaseService.getCounty(countyId);
     if (snapshot) {
       commit('setSelectedCounty', snapshot)
     }
    },
    async getSelectedCountyOrdinance({ state, commit }) {
      if (state.selectedCounty) {
        // typescript null why o why.
        const snapshot = await firebaseService.getCountyOrdinance(state.selectedCounty!.id);
        if (snapshot) {
          commit('setSelectedCountyOrdinance', snapshot)
        }
      }
    },
    async getSelectedCountyExcludedDates({ state, commit }) {
      if (state.selectedCounty) {
        // typescript null why o why.
        const snapshot = await firebaseService.getCountyExcludedDates(state.selectedCounty.id);
        if (snapshot) {
          commit('setSelectedCountyExcludedDates', snapshot)
        }
      }
    },
    async submitPermitApplication({ state, commit, dispatch }, formData): Promise<string> {
      try {
        if (formData && state.selectedCounty) {
          // TODO clean this up.
          formData.state = 'Georgia'
          formData.permitId = Number(((new Date().getMonth() + 1 ).toString() + new Date().getDate().toString()) + Math.floor(Math.random() * 9000000) ) + 1000000;
          // formData.ipAddress = '127.0.0.1'
          formData.submittedAt = new Date()
          Object.keys(formData).forEach(key => formData[key] === undefined ? delete formData[key] : {});
          const doc = await firebaseService.submitPermitApplication(state.selectedCounty!.id, formData);
          if (doc) {
            await commit('setcompletedPermit', doc)
          }
          await dispatch('getLastPermit')
          return '';
        } else {
          return 'could not submit permit'
        }
      } catch (error) {
        return error.toString()
      }
    },

    // ADMIN RELATED

    async login(context, formData: { email: string; password: string }): Promise<string> {
      try {
        if (formData) {
          await firebaseService.login(formData.email, formData.password)
          // user creds are automatically set by authChangeDetect.
          return ''
        } else {
          return 'login user'
        }
      } catch (error) {
        return error.toString()
      }
    },
    async logout(): Promise<string> {
      try {
        await firebaseService.logout()
        // user creds are automatically cleared by authChangeDetect.
        return ''
      } catch (error) {
        return error.toString()
      }
    },
    async getMyCounties({ state, commit, dispatch }) {
      if (state.myUser) {
        const snapshot = await firebaseService.getMyCounties(state.myUser.uid);
        if (snapshot) {
          commit('setMyCounties', snapshot)
          // if no selected county, lets set one up!
          if (!snapshot.empty && !state.selectedCounty) {
            await commit('setSelectedCounty', snapshot)
            dispatch('getSelectedCountyOrdinance')
            dispatch('getSelectedCountyExcludedDates')
          }
        }
      }

    },
    async searchPermits({ state, commit }, query) {
      if (state.selectedCounty) {
        // typescript null why o why.
        const q = await firebaseService.formatQuery(state.selectedCounty!.id, query)
        const snapshot = await firebaseService.getPermits(q, state.lastDoc, state.pageSize)
        if (snapshot) {
          commit('setPermits', snapshot)
        }
      }
    },
    async pageBackPermits({ state, commit }, query) {
      if (state.selectedCounty) {
        // typescript null why o why.
        const q = await firebaseService.formatQuery(state.selectedCounty!.id, query)
        const snapshot = await firebaseService.getPermitsPageBack(q, state.firstDoc, state.pageSize)
        if (snapshot) {
          commit('setPermits', snapshot)
        }
      }
    },
    async setPageSize({commit}, pageSize) {
      commit('setPageSize', pageSize)
    },
    async updateCounty(context, county) {
      // TODO county must have id?
      try {
        await firebaseService.updateCounty(county.id, county);
      } catch (error) {
        return error.toString()
      }
    },

    async updateOrdinance({ state, dispatch }, o: { id: string; sections: string[] }) {
      try {
        if (state.selectedCounty) {
          await firebaseService.updateOrdinance(state.selectedCounty!.id, o.id, o.sections);
          await dispatch('getSelectedCountyOrdinance')
          return ''
        } else {
          return 'could not update ordinance'
        }
      } catch (error) {
        return error.toString()
      }
    },

    async removeExcludedDate({ state, dispatch }, exDate) {
      try {
        if (state.selectedCounty) {
          await firebaseService.removeExcludedDate(state.selectedCounty!.id, exDate.id);
          dispatch('getSelectedCountyExcludedDates')
          return ''
        }
        return 'could not remove excluded date'
      } catch (error) {
        return error.toString()
      }
    },

    async addExcludedDates({ state, dispatch }, exDates) {
      try {
        if (state.selectedCounty) {
          await firebaseService.addExcludedDates(state.selectedCounty!.id, exDates);
          dispatch('getSelectedCountyExcludedDates')
          return ''
        }
        return 'could not add dates'
      } catch (error) {
        return error.toString()
      }
    },

    async getWeatherAlerts({ commit }): Promise<string> {
        try {
          const alerts: {data: {features: any[]; title: string; updated: FirestoreDate}} = await firebaseService.getWeatherAlerts()
          if (alerts && alerts.data) {
            commit('setWeatherAlerts', alerts.data)
          } else {
            commit('setWeatherAlerts', {features: [], updated: new FirestoreDate(new Date().getSeconds()), title: 'Could not load weather alerts.'})
          }
          return ''
        } catch (error) {
          return error.toString()
        }
    },

    async loadCustomer({ commit }, firebaseUser: {uid: string}) {
      try {
        if (firebaseUser) {
          const snapshot = await firebaseService.loadCustomer(firebaseUser.uid);
          commit('setCustomer', snapshot)
          return ''
        } else {
          commit('setCustomer', null)
        }
        return 'could not load customer'
      } catch (error) {
        return error.toString()
      }
    },

    async updateCustomer({ state, dispatch }, formData: CustomerFormData): Promise<string> {
      try {
        if (state.myUser) {
          formData.uid = state.myUser.uid;
          formData.email = state.myUser.email;
          const result = await firebaseService.updateCustomer(formData);
          dispatch("loadCustomer", formData)
          return ''
        } else {
          throw Error('could not update customer')
        }
      } catch (error) {
        if (error && error.details) {
          return error.details
        }
        return error.toString()
      }
    },

    setScroll({ commit }, scrollTop) {
      commit("setScroll", scrollTop)
    }
  },
  mutations: {
    completeInit(state) {
      state.initComplete = true
    },
    resetStore(state) {
      Object.assign(state, getDefaultState())
    },
    setCounties(state, snapshot) {
      state.counties = [];
      snapshot.forEach((doc: any) => { // doc.data() is County type.
        // console.log(`${doc.id} => ${doc.data()}`);
        const c = doc.data();
        c.id = doc.id // have to slap it on
        state.counties.push(c);
      });
    },
    setMyCounties(state, snapshot) {
      state.myCounties = [];
      snapshot.forEach((doc: any) => {
        const c = doc.data();
        c.id = doc.id
        state.myCounties.push(c);
      });
    },
    setFormData(state, formData) {
      Object.keys(formData).forEach(key => formData[key] === undefined ? delete formData[key] : {});
      state.formData = Object.assign(state.formData ?? {}, formData)
      localStorage.setItem('gbpFormData', JSON.stringify(state.formData))
    },
    setcompletedPermit(state, doc) {
      // I think its an array of one.
      const c = doc.data();
      c.id = doc.id // have to slap it on
      state.completedPermit = c;
    },
    setLastPermits(state, { snapshot, snapshot2 }) {
      if (snapshot.empty && snapshot2.empty) {
        state.lastPermit = null
        state.canApply = true
        return
      }
      state.permits = [];
      const temp: any[] = []
      const temp2: any[] = []
      snapshot.forEach((doc: any) => { // doc.data() is FirePermit type.
        const c = doc.data();
        c.id = doc.id // have to slap it on
        temp.push(c);
      });
      snapshot2.forEach((doc: any) => { // doc.data() is FirePermit type.
        const c = doc.data();
        c.id = doc.id // have to slap it on
        temp2.push(c);
      });
      const together = [...temp, ...temp2]
      const output: any[] = []
      together.forEach(p => {
        const index = output.findIndex(op => op.permitId == p.permitId)
        if (index == -1) {
          output.push(p)
        }
      })
      if (output.length) {
        state.lastPermit = output[0] // TODO should only be one in this.
        state.canApply = false
      } else {
        state.lastPermit = null
        state.canApply = true
      }
    },
    setPermits(state, snapshot) {
      if (snapshot.size == 0) {
        state.lastDoc = null
        state.firstDoc = null
        return
        // TODO , dont let it page to nothing!
      }
      state.permits = [];
      snapshot.forEach((doc: any) => { // doc.data() is FirePermit type.
        const c = doc.data();
        c.id = doc.id // have to slap it on
        if (state.permits.length == 0) {
          // first element. for paging.
          state.firstDoc = doc
        }

        state.permits.push(c);
      
        if (state.permits.length == snapshot.size) {
          // last element. for paging.
          state.lastDoc = doc
        }
      });
    },
    setPageSize(state, pageSize) {
      state.pageSize = pageSize
      state.lastDoc = null
      state.firstDoc = null
      // clear the paging
    },
    setMyUser(state, firebaseUser) {
      state.myUser = firebaseUser
    },
    // if coming from firebase, use this one.
    setSelectedCounty(state, snapshot) {
      snapshot.forEach((doc: any) => { // doc.data() is County type.
        const c = doc.data();
        c.id = doc.id // have to slap it on
        state.selectedCounty = c;
        localStorage.setItem('gbpCounty', JSON.stringify(c))
      });
    },
    // needs to be a pre formatted county.
    saveSelectedCounty(state, county) {
      state.selectedCounty = county
      localStorage.setItem('gbpCounty', JSON.stringify(county))
    },
    setSelectedCountyExcludedDates(state, snapshot) {
      state.selectedCountyExcludedDates = [];
      snapshot.forEach((doc: any) => { // doc.data() is ExcludedDate type.
        const c = doc.data();
        c.id = doc.id // have to slap it on
        state.selectedCountyExcludedDates.push(c);
      });
    },
    setSelectedCountyOrdinance(state, snapshot) {
      snapshot.forEach((doc: any) => { // doc.data() is Ordinance type.
        const c = doc.data();
        c.id = doc.id // have to slap it on
        state.selectedCountyOrdinance = c;
      });
    },
    setWeatherAlerts(state, alerts) {
      console.log(alerts)
      state.weatherAlerts = alerts;
    },
    setCustomer(state, snapshot) {
      if (snapshot) {

        const c = snapshot.data();
        c.id = snapshot.id; // have to slap it on
        state.myCustomer= c;
      } else {
        // clear customer then.
        state.myCustomer = null;
      }
    },
    setScroll(state, scrollTop) {
      state.scrollTop = scrollTop
    }
  }
})

export interface CustomerFormData {
  firstName: string;
  lastName: string;
  address: string;
  city: string;
  zip: string;
  state: string;
  acceptTerms: boolean;
  billingAddress: string;
  nameOnCard: string;
  paymentMethod: object;
  uid?: string;
  email?: string;
}