<template>
  <ion-row class="ion-justify-items-center">
    <ion-col size-md="4" size="auto"> </ion-col>
    <ion-col size-md="4" class="flex ion-padding-top">
      <ion-text class="auto pointer" color="light" @click="() => route ? $router.push(route) : $router.go(-1)">
        <a style="text-decoration: underline">
          {{ $t("home.backButton") }}</a
        ></ion-text
      >
    </ion-col>
    <ion-col size-md="4" size="auto"> </ion-col>
  </ion-row>
</template>
<script lang='ts'>
import { IonCol, IonRow, IonText } from "@ionic/vue";

import { defineComponent } from "vue";

export default defineComponent({
  name: "BackButtonLink",
  components: {
    IonCol,
    IonRow,
    IonText,
  },
  props: {
    route: {
      type: String,
      default: null
    }
  }
});
</script>
