/*
Not really a plugin but meh.
vue-snack With some custom global configuration
*/
export const snackBarConfig = {
  time: 2500,
  close: true,
  font: {
   family: 'Open Sans, sans-serif',
    size: '14px',
  },
  methods: [{
    name: 'test',
    color: 'green'
  }]
};
/*
import { useSnackbarPlugin } from 'snackbar-vue';

// ...
const snack = useSnackbarPlugin();
// Simple message with the close button 
snack.show({
        position: 'bottom',
        text: `Test Show ${Date.now()}`,
        time: 2000,
        close: true,
      });
// Alert message with an action 
snack.danger({
        position: 'bottom-right',
        text: `Test Danger ${Date.now()}`,
        button: 'ACTION',
        time: 2000,
        close: false,
        action: () => { console.log('do something'); },
      });
// Success message with an action 
snack.success({
        position: 'bottom-left',
        text: `Test Success ${Date.now()}`,
        button: 'ACTION',
        time: 2000,
        action: () => { console.log('do something'); },
      });
// Custom method created from the plugin initialization
snack.test({
  background: '#ffffff',
  textColor: '#000000',
  position: 'top',
  text: `prova ${Date.now()}`,
  button: 'REDO',
  time: 5000,
  close: true,
  action: () => { console.log('do something'); },
});
*/