
import { IonButton, IonContent, IonGrid, IonIcon, IonCol, IonRow, IonPage } from "@ionic/vue";
import { defineComponent } from "vue";
import { printOutline } from "ionicons/icons";
import GbpFooter from "@/components/Footer.vue";
import OrdinanceForm from "@/components/admin/OrdinanceForm.vue";

export default defineComponent({
  name: "CountyOrdinance",
  components: {
    IonButton,
    IonContent,
    IonGrid,
    IonIcon,
    IonCol,
    IonRow,
    IonPage,
    GbpFooter,
    OrdinanceForm,
  },
  setup() {
    return { printOutline }
  },
  computed: {
    selectedCountyOrdinance(): any {
      return this.$store.state.selectedCountyOrdinance;
    },
    selectedCounty(): any {
      return this.$store.state.selectedCounty;
    },
  },
  mounted() {
    setTimeout(() => {
      if (!this.selectedCountyOrdinance) {
        this.$store.dispatch("getSelectedCountyOrdinance");
      }
    }, 100);
  },
  methods: {
    goToPrintPage() {
      this.$router.push("/ordinancepreview?id=" + this.selectedCounty.id)
    }
  }
});
