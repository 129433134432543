
import { IonCol, IonGrid, IonIcon, IonRow, IonSpinner, IonText } from "@ionic/vue";

import { defineComponent, ref } from "vue";
import { checkmarkCircleOutline, closeCircleOutline } from "ionicons/icons";
import { dateMixin } from "../../utils/date.format";

export default defineComponent({
  name: "ArePermitsAvailable",
  mixins: [dateMixin],
  components: {
    IonCol,
    IonGrid,
    IonIcon,
    IonRow,
    IonSpinner,
    IonText,
  },
  setup() {
    const loading = ref(false);
    const init = ref(false);
    return {
      loading,
      init,
      closeCircleOutline,
      checkmarkCircleOutline,
    };
  },
  mounted() {
    setTimeout(() => {
      this.init = true
    }, 300)
  },
  computed: {
    selectedCounty(): any {
      return this.$store.state.selectedCounty;
    },
    excludedDates(): any[] {
      return this.$store.state.selectedCountyExcludedDates;
    },
    areAvailable(): boolean {
      return !this.reason;
    },
    reason(): string {
      let reason = '';
        if (this.excludedDates && this.excludedDates.length) {
          this.excludedDates.forEach(
            (d: { date: { seconds: number; nanoseconds: number }; reason: string }) => {
              if (this.isSameAsToday(d.date.seconds)) {
                reason = d.reason;
              }
            }
          );
      }
      if (this.selectedCounty && !this.selectedCounty.enabled) {
        reason = 'Permits are not available for this county yet.'
      }
      return reason;
    },
    today(): any {
      return this.todaysDate()
    }
  },
  // watch: {
  //   excludedDates() {
  //     // TODO improve jank.
  //     this.loading = true;
  //     setTimeout(() => {
  //       this.loading = false;
  //     }, 1000);
  //   },
  // },
});
