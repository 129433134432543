<template>
  <div v-if="show && msg" class="pt-1 ion-padding-horizontal">
    <ion-text color="danger">{{
      show && msg ? msg : ""
    }}</ion-text>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
  import { IonText,
} from "@ionic/vue";
export default defineComponent({
    name: 'InputError',
    components: {
        IonText,
    },
    props: {
        show: {
            type: Boolean,
            default: false
        },
        msg: {
            type: String,
            default:  "\u0000"
        }
    }
})
</script>
