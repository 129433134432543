<template>
  <ion-page>
    <ion-content class="ion-padding-bottom" color="light">
    <ion-grid>
        <ion-row>
          <ion-col size-lg="2"> </ion-col>
          <ion-col size-lg="8" size="12" class="flex">
            <h1>Billing</h1>
          </ion-col>
          <ion-col size-lg="2" > </ion-col>
        </ion-row>
        <ion-row class="pt-0">
          <ion-col size-lg="2">  </ion-col>
          <ion-col size-lg="8" size="12"  class="flex">
            <h4>Review your latest invoices and payment details here.  Invoices are generated on a monthly interval based on the amount of permits issued each month.</h4>
          </ion-col>
          <ion-col size-lg="2"> </ion-col>
        </ion-row>
        <billing-details/>
    <ion-row>
    </ion-row>
    </ion-grid>
  </ion-content>
  <ion-footer>
    <gbp-footer/>
  </ion-footer>
  </ion-page>
</template>

<script lang="ts">
import BillingDetails from "@/components/settings/BillingDetails.vue";
import GbpFooter from "@/components/Footer.vue";
import {
  IonContent,
  IonPage,
  IonGrid,
  IonRow,
  IonCol,
  IonFooter
} from "@ionic/vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "BillingPage",
  components: {
    IonContent,
    IonPage,
    IonGrid,
    IonRow,
    IonCol,
    IonFooter,
    GbpFooter,
    BillingDetails
  },
  mounted() {
    this.$store.dispatch("initFromStorage");
  },
  computed: {
    selectedCounty(): any {
      return this.$store.state.selectedCounty;
    },
  },
});
</script>