import { createApp } from 'vue'
import { IonicVue } from '@ionic/vue';
import { Storage } from '@ionic/storage';
// import vuetify from './plugins/vuetify'
import i18n from './plugins/il8n'
import {SnackbarPlugin} from 'snackbar-vue';
import { snackBarConfig } from './plugins/vue-snack';
import { FirebaseService } from './services/firebase.service';
import { store } from './store';
import App from './App.vue'
import router from './router'


/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
// import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';

// optionally import default styles
import 'snackbar-vue/dist/snackbar-vue.common.css';


// available globally.
const localStorage = new Storage();
localStorage.create();

FirebaseService.initFirebase();

const app = createApp(App)
  .use(IonicVue, {
    mode: 'md'
  })
  .use(router)
  // .use(vuetify)
  .use(store)
  .use(i18n)
  .use(SnackbarPlugin, snackBarConfig)


router.isReady().then(() => {
  app.mount('#app');
});