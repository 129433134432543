
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonList,
  IonIcon,
  IonItem,
  IonInput,
  IonTextarea,
  IonLabel,
  IonToolbar,
  IonTitle,
  modalController,
} from "@ionic/vue";
import { defineComponent, ref } from "vue";
import { closeOutline } from "ionicons/icons";
import {
  addressFake,
  formatPhoneNumber,
  isRequired,
  max500Chars,
  threeCharacters,
  validateEmail,
  validatePhone,
  validateZipCode,
} from "@/utils/form.validators";
import { useField, useForm } from "vee-validate";
import InputError from "../permit/InputError.vue";

export default defineComponent({
  name: "Modal3",
  props: {
    initFormData: {
      type: Object,
      default: null,
    },
  },
  setup() {
    const loaded = ref(false);
    const showErrors = ref(false);

    const { value: firstName, errorMessage: firstNameError } = useField(
      "firstName",
      isRequired
    );
    const { value: lastName, errorMessage: lastNameError } = useField(
      "lastName",
      isRequired
    );
    const { value: address, errorMessage: addressError } = useField(
      "address",
      addressFake
    );
    const { value: city, errorMessage: cityError } = useField(
      "city",
      threeCharacters
    );
    const { value: zip, errorMessage: zipError } = useField(
      "zip",
      validateZipCode
    );
    const { value: phoneNo, errorMessage: phoneNoError } = useField(
      "phoneNo",
      validatePhone
    );
    const { value: email, errorMessage: emailError } = useField(
      "email",
      validateEmail
    );
    const {
      value: additionalLocationInfo,
      errorMessage: additionalLocationInfoError,
    } = useField("additionalLocationInfo", max500Chars);

    const { handleSubmit } = useForm();
    const onSubmit = handleSubmit(() => {
      modalController.dismiss({
        firstName,
        lastName,
        address,
        city,
        zip,
        phoneNo,
        email,
        additionalLocationInfo,
      });
    });

    return {
      firstName,
      lastName,
      address,
      city,
      zip,
      phoneNo,
      email,
      additionalLocationInfo,
      firstNameError,
      lastNameError,
      addressError,
      cityError,
      zipError,
      phoneNoError,
      emailError,
      additionalLocationInfoError,
      loaded,
      closeOutline,
      onSubmit,
      showErrors,
    };
  },
  components: {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonList,
    IonIcon,
    IonItem,
    IonInput,
    IonTextarea,
    IonLabel,
    IonTitle,
    IonToolbar,
    InputError,
  },
  computed: {
    formError(): boolean {
      if (
        this.firstNameError ||
        this.lastNameError ||
        this.addressError ||
        this.cityError ||
        this.zipError ||
        this.phoneNoError ||
        this.emailError ||
        this.additionalLocationInfoError
      ) {
        return true;
      }
      return false;
    },
  },
  mounted() {
    if (this.initFormData) {
      this.firstName = this.initFormData.firstName;
      this.lastName = this.initFormData.lastName;
      this.address = this.initFormData.address;
      this.city = this.initFormData.city;
      this.zip = this.initFormData.zip;
      this.phoneNo = this.initFormData.phoneNo;
      this.email = this.initFormData.email;
      this.additionalLocationInfo = this.initFormData.additionalLocationInfo;
    }
    this.loaded = true;
  },
  methods: {
    closeForm() {
      modalController.dismiss();
    },
    formatPhone(fon: any) {
      this.phoneNo = formatPhoneNumber(fon.detail.value);
    },
    formatZip(zip: any) {
      this.zip = this.zipHip(zip.detail.value).slice(0,5)
    },
    zipHip(zip: string) {
      return ("" + zip).replace(/\D/g, "");
    }
  },
});
