<template>
  <ion-page>
    <ion-content ref="content" color="light">
      <logo-header />
      <ion-grid>
        <ion-row class="ion-padding-top" />
        <ion-row>
          <ion-col size-md="2" size="auto"> </ion-col>
          <ion-col
            size-md="8"
            class="ion-text-center ion-padding-top ion-padding-horizontal"
          >
            <h2 class="ion-no-margin">
              {{ $t("permit.instructions") }}
            </h2>
          </ion-col>
          <ion-col size-md="2" size="auto"> </ion-col>
        </ion-row>
      </ion-grid>
      <application-form />
      <fire-permit-form :submitHandler="onSubmit" :loading="loading" />
      <gbp-footer/>
    </ion-content>
    <ion-footer v-if="loading">
      <ion-progress-bar type="indeterminate"></ion-progress-bar>
    </ion-footer>
  </ion-page>
</template>

<script lang="ts">
import ApplicationForm from "@/components/permit/ApplicationForm.vue";
import {
  IonContent,
  IonCol,
  IonFooter,
  IonGrid,
  IonRow,
  IonPage,
  IonProgressBar,
} from "@ionic/vue";
import { defineComponent, ref } from "vue";
import LogoHeader from "../components/LogoHeader.vue";
import FirePermitForm from "../components/permit/FirePermitForm.vue";
import GbpFooter from "@/components/Footer.vue";

export default defineComponent({
  name: "PermitPage",
  components: {
    FirePermitForm,
    IonContent,
    IonCol,
    IonRow,
    IonGrid,
    IonPage,
    IonFooter,
    IonProgressBar,
    LogoHeader,
    ApplicationForm,
    GbpFooter
  },
  setup() {
    const loading = ref(false)
    const content = ref()
    return { loading, content }
  },
  async updated() {
    this.content.$el.scrollToTop()
  },
  async mounted() {
    this.$store.dispatch("initFromStorage");
  },
  methods: {
    async onSubmit(values: any) {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
        this.$router.push("/check");
      }, 500)
    },
  },
});
</script>