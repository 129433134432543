
import {
  IonCol,
  IonCheckbox,
  IonGrid,
  IonItem,
  IonInput,
  IonLabel,
  IonList,
  IonRow,
  modalController
} from "@ionic/vue";

import { defineComponent, ref } from "vue";
import InputError from "./../permit/InputError.vue";
import { useForm, useField } from "vee-validate";
import { isRequired, validateEmail, validateZipCode } from "@/utils/form.validators";
import StripeBox from "./StripeBox.vue";
import { useSnackbarPlugin } from "snackbar-vue";
import TermsModal from "./TermsModal.vue";

export default defineComponent({
  name: "OnboardForm",
  components: {
    IonCol,
    IonCheckbox,
    IonGrid,
    IonItem,
    IonInput,
    IonLabel,
    IonList,
    IonRow,
    InputError,
    StripeBox,
  },
  setup() {
    const { handleSubmit } = useForm();
    const onSubmit = handleSubmit(() => {
      //
    });
    const snack = useSnackbarPlugin();

    const { value: firstName, errorMessage: firstNameError } = useField(
      "firstName",
      isRequired
    );
    const { value: lastName, errorMessage: lastNameError } = useField(
      "lastName",
      isRequired
    );
    const { value: address, errorMessage: addressError } = useField(
      "address",
      isRequired
    );
    const { value: city, errorMessage: cityError } = useField(
      "city",
      isRequired
    );
    const { value: state, errorMessage: stateError } = useField(
      "state",
      isRequired
    );
    const { value: zip, errorMessage: zipError } = useField(
      "zip",
      validateZipCode
    );
    const {
      value: acceptTerms,
      errorMessage: acceptTermsError,
    } = useField("acceptTerms");

    const { value: nameOnCard, errorMessage: nameOnCardError } = useField(
      "nameOnCard",
      isRequired
    );
    const { value: billingAddress, errorMessage: billingAddressError } = useField(
      "billingAddress",
      isRequired
    );
    const { value: paymentMethod, errorMessage: paymentMethodError } = useField(
      "paymentMethod",
    //   isRequired
    );
    
    state.value = ref("GEORGIA")

    return {
      firstName,
      lastName,
      address,
      city,
      zip,
      state,
      acceptTerms,
      billingAddress,
      nameOnCard,
      paymentMethod,
      firstNameError,
      lastNameError,
      addressError,
      cityError,
      zipError,
      stateError,
      billingAddressError,
      acceptTermsError,
      nameOnCardError,
      paymentMethodError,
      onSubmit,
      snack,
    };
  },
  computed: {
    formValid(): any {
      return this.firstNameError ||
        this.lastNameError ||
        this.addressError ||
        this.cityError ||
        this.zipError ||
        this.stateError ||
        this.billingAddressError ||
        this.acceptTermsError ||
        this.nameOnCardError ||
        !this.acceptTerms
    }
  },
  methods: {
    async updatePayment(paymentMethod: any) {
      // submit the form if we have everything ,
      // if (!this.formValid)
      // {
      //   this.snack.show({
      //     position: "bottom",
      //     text: "Please check submission.",
      //     time: 2500,
      //     close: true,
      //   });
      //   return;
      // }
      // show snack saying submitting..
      this.snack.show({
        position: "bottom",
        text: "Submitting..",
        time: 2000,
        close: true,
      });
      this.snack.show({
        position: "bottom",
        text: "Please wait.. this takes a few seconds..",
        time: 3000,
        close: true,
      });

      // do something...
      const errorMsg = await this.$store.dispatch("updateCustomer", {
        firstName: this.firstName,
        lastName: this.lastName,
        address: this.address,
        city: this.city,
        zip: this.zip,
        state: this.state,
        acceptTerms: this.acceptTerms,
        billingAddress: this.billingAddress,
        nameOnCard: this.nameOnCard,
        paymentMethod: paymentMethod,
      });

      if (errorMsg) {
        this.snack.show({
          position: "bottom",
          text: errorMsg,
          time: 2500,
          close: true,
        });
        return;
      }

      await this.$store.dispatch("loadCustomer");

      this.snack.show({
        position: "bottom",
        text: "Success. Payment Information Updated.",
        time: 2500,
        close: true,
      });

      setTimeout(() => {
        this.$router.push("/dashboard");
      }, 2500);

      // show snack saying success.. or err

      // nav to dashboard?
    },
    async openModal() {
      const modal = await modalController.create({
        component: TermsModal,
      });
      modal.present();
      const result = await modal.onDidDismiss();
      if (result && result.data) {
        this.$store.dispatch("saveFromListSelectedCounty", result.data);
      }
    },
    formatZip(zip: any) {
      this.zip = this.zipHip(zip.detail.value).slice(0,5)
    },
    zipHip(zip: string) {
      return ("" + zip).replace(/\D/g, "");
    }
  },
});
