<template>
  <ion-item lines="none" style="max-height: 40px">
    <div class="flex ion-justify-content-between w100">
      <div>{{ caption }}:</div>
      <div class="ion-text-end">{{ value }}</div>
    </div>
  </ion-item>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import {
  IonItem,
} from "@ionic/vue";

export default defineComponent({
  components: {
      IonItem
  },
  props: {
    caption: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
  },
});
</script>
