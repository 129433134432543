<template>
  <ion-page>
    <ion-content scroll-events @ionScroll="handleScroll" class="ion-padding-top"  color="light">
      <permit-dashboard/>
      <ion-grid style="min-height: 80vh">
        <ion-row>
          <ion-col size-lg="1"> </ion-col>
          <ion-col size-lg="10" size="12">
            <h1>{{$t("dashboard.latestPermits")}}</h1>
          </ion-col>
          <ion-col size-lg="1"> </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size-lg="1"> </ion-col>
          <ion-col size-lg="10" size="12" class="flex ion-padding-horizontal">
            <h4>{{$t("dashboard.tableTalk")}}</h4>
          </ion-col>
          <ion-col size-lg="1" > </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size-lg="1"> </ion-col>
          <ion-col size-lg="10"  size="12" >
            <div>
            <data-table />
            </div>
          </ion-col>
          <ion-col size-lg="1"> </ion-col>
        </ion-row> 
      </ion-grid>
      <gbp-footer/>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { IonContent, IonGrid, IonRow, IonCol, IonPage } from "@ionic/vue";
import { defineComponent } from "vue";
import GbpFooter from "@/components/Footer.vue";
import PermitDashboard from "@/components/admin/PermitDashboard.vue";
import DataTable from "@/components/admin/DataTable.vue";

export default defineComponent({
  name: "SearchPermitsPage",
  components: {
    IonContent,
    IonPage,
    IonGrid,
    IonRow,
    IonCol,
    GbpFooter,
    PermitDashboard,
    DataTable
  },
  methods: {
    handleScroll($event: any) {
      this.$store.dispatch("setScroll", $event.detail.scrollTop )
    }
  }
});
</script>