// Firebase App (the core Firebase SDK) is always required and must be listed first
import firebase from "firebase/app"

// If you enabled Analytics in your project, add the Firebase SDK for Analytics
import "firebase/analytics"

// Add the Firebase products that you want to use
import "firebase/auth";
import "firebase/firestore";
import 'firebase/app-check';
import 'firebase/functions';

import { County, countyConverter } from "@/models/county";
import { FirePermit, permitConverter } from "@/models/permit";
import { Ordinance, ordinanceConverter } from "@/models/ordinance";
import { excludedConverter, ExcludedDate } from "@/models/excluded";
import { asyncForEach } from "@/utils/form.utils";
import { dateMixin } from "@/utils/date.format";
import { customerConverter } from "@/models/customer";

// this is ok to have in src.
const firebaseConfig = {
  apiKey: "AIzaSyDAfic8Fo3ekfQMxVQGMf-nuaEj_7SYPKg",
  authDomain: "georgiaburnpermits.firebaseapp.com",
  projectId: "georgiaburnpermits",
  storageBucket: "georgiaburnpermits.appspot.com",
  messagingSenderId: "268510895598",
  appId: "1:268510895598:web:17a24ff4b8188f5419c2ff",
  measurementId: "G-2EEM20V18N"
}

// recaptcha key for georgiaburnpermits.com

const firebaseAppCheck = {
  siteKey: '6LeYqgkbAAAAAHlUBYLwgGxlFYGEnnyfFo1eB0R_'
}

let appCheck: firebase.appCheck.AppCheck | null = null

export class FirebaseService {
  public static initFirebase() {
    // console.log('init firebase v333')
    // Initialize Firebase. This needs to run early in life.
    firebase.initializeApp(firebaseConfig)



    appCheck = firebase.appCheck();
    appCheck.activate(firebaseAppCheck.siteKey);

    // firebase.functions().useEmulator("localhost", 5001);
  }
  // public appCheck: firebase.appCheck.AppCheck| null = null
  public auth: firebase.auth.Auth | null = null
  public db: firebase.firestore.Firestore | null = null
  public fcn: firebase.functions.Functions | null = null

  public getAuth(): firebase.auth.Auth {
    if (this.auth == null) {
      this.auth = firebase.auth()
    }
    return this.auth
  }
  public getDb(): firebase.firestore.Firestore {
    if (this.db == null) {
      this.db = firebase.firestore()
    }
    return this.db
  }

  public getFcns(): firebase.functions.Functions {
    if (this.fcn == null) {
      // this.fcn = firebase.functions()
      // this.fcn = firebase.app().functions('us-central1');
      this.fcn = firebase.app().functions();
    }
    return this.fcn
  }

  // permit related requests.

  formatQuery(countyId: string, query:
    {
      start: Date;
      end: Date;
      active?: boolean;
      address?: string;
      email?: string;
      phoneNo?: string;
      type?: string;
    }
    ): firebase.firestore.Query {

    let q: any = this.getDb().collection("counties")
      .doc(countyId)
      .collection('permits')

    // if active, we don't use the start and end
    if (query.active) {
      q = q.where('submittedAt', '>', dateMixin.methods.yesterday8pmDate())
    } else {
      q = q.where('submittedAt', '>', query.start).where('submittedAt', '<', query.end)
    }
    if (query.address) {
      q = q.where('address', '==', query.address)
    }
    if (query.email) {
      q = q.where('email', '==', query.email)
    }
    if (query.phoneNo) {
      q = q.where('phoneNo', '==', query.phoneNo)
    }
    return q.orderBy('submittedAt')
  }

  getPermits(query: firebase.firestore.Query, lastDoc: firebase.firestore.DocumentData, pageSize: number) {
    if (lastDoc) {
      return query
        .startAfter(lastDoc)
        .limit(pageSize)
        .withConverter(permitConverter)
        .get()
    }
    // page one.
    return query
      .withConverter(permitConverter)
      .limit(pageSize)
      .get()
  }

  getPermitsPageBack(query: firebase.firestore.Query, firstDoc: firebase.firestore.DocumentData, pageSize: number) {
    if (firstDoc) {
      return query
        .endBefore(firstDoc)
        .limitToLast(pageSize)
        .withConverter(permitConverter)
        .get()
    }
    // page one.
    return query
      .withConverter(permitConverter)
      .limit(pageSize)
      .get()
  }

  public async getMyPermits(countyId: string, field: string, value: string): Promise<firebase.firestore.QuerySnapshot<FirePermit>> {
    return this.getDb().collection("counties")
      .doc(countyId)
      .collection('permits')
      .where(field, "==", value)
      .where('submittedAt', '>', dateMixin.methods.yesterday8pmDate())
      .orderBy('submittedAt', 'desc')
      .withConverter(permitConverter)
      .get()
  }

  public async getCountyPermits(countyId: string): Promise<firebase.firestore.QuerySnapshot<FirePermit>> {
    return this.getDb().collection("counties")
      .doc(countyId)
      .collection('permits')
      .withConverter(permitConverter)
      .get()
  }
  public async getCounty(countyId: string): Promise<firebase.firestore.QuerySnapshot<County>> {
    return this.getDb().collection("counties")
      .where(firebase.firestore.FieldPath.documentId(), '==', countyId)
      .withConverter(countyConverter)
      .get()
  }
  public async getAllCounties(): Promise<firebase.firestore.QuerySnapshot<County>> {
    return this.getDb().collection("counties")
      .withConverter(countyConverter)
      .get()
  }
  public async searchCounties(search: string): Promise<firebase.firestore.QuerySnapshot<County>> {
    if (!search) {
      return this.getDb().collection("counties")
        .limit(20) // TODO is this cool. very.
        .withConverter(countyConverter)
        .get()
    }
    return this.getDb().collection("counties")
      .orderBy(firebase.firestore.FieldPath.documentId())
      .startAt(search.toUpperCase()).endAt(search.toUpperCase() + '\uf8ff')
      .limit(20) // TODO is this cool.
      .withConverter(countyConverter)
      .get()
  }
  public async getCountyExcludedDates(countyId: string): Promise<firebase.firestore.QuerySnapshot<ExcludedDate>> {
    return this.getDb().collection("counties")
      .doc(countyId)
      .collection('excludedDates')
      .withConverter(excludedConverter)
      .get()
  }
  public async getCountyOrdinance(countyId: string): Promise<firebase.firestore.QuerySnapshot<Ordinance>> {
    return this.getDb().collection("counties")
      .doc(countyId)
      .collection('ordinances')
      .withConverter(ordinanceConverter)
      .get()
  }
  public async submitPermitApplication(countyId: string, formData: any) {
    const docRef = await this.getDb().collection("counties")
      .doc(countyId)
      .collection('permits')
      .add(formData);

    // TODO , this doesn't belong, the store should do this
    return this.getDb().collection("counties")
      .doc(countyId)
      .collection('permits').doc(docRef.id)
      .withConverter(permitConverter)
      .get()
  }

  // admin related methods.


  // Auth.onAuthStateChanged(firebaseUser => { });
  public onAuthStateChange(ftn: any) {
    return this.getAuth().onAuthStateChanged(ftn)
  }

  public async login(email: string, password: string): Promise<firebase.auth.UserCredential> {
    return this.getAuth().signInWithEmailAndPassword(email, password)
  }

  public async logout(): Promise<void> {
    return this.getAuth().signOut()
  }

  public async updateCounty(countyId: string, county: County): Promise<void> {
    return this.getDb().collection("counties")
      .doc(countyId)
      .update(county)
  }

  public async updateOrdinance(countyId: string, ordinanceId: string, ordinanceArray: string[]): Promise<void> {
    return this.getDb().collection("counties")
      .doc(countyId)
      .collection('ordinances')
      .doc(ordinanceId)
      .update({
        sections: ordinanceArray,
        lastUpdated: new Date()
      })
  }

  public async addExcludedDates(countyId: string, exDates: ExcludedDate[]): Promise<void> {
    const batch = this.getDb().batch()
    await asyncForEach(exDates, async (doc: ExcludedDate) => {
      const docRef = await this.getDb().collection("counties")
        .doc(countyId)
        .collection('excludedDates')
        .add(doc)
      batch.set(docRef, doc)
    })
    batch.commit()
    return
  }

  public async removeExcludedDate(countyId: string, exDateId: string): Promise<void> {
    return this.getDb().collection("counties")
      .doc(countyId)
      .collection("excludedDates")
      .doc(exDateId)
      .delete();
  }

  public async loadCustomer(uid: string): Promise<any> {
    return this.getDb().collection("customers")
      .doc(uid)
      .withConverter(customerConverter)
      .get();
  }

  public async updateCustomer(formData: any): Promise<any> {
    const updateCustomer = this.getFcns().httpsCallable('updateCustomer');
    return updateCustomer(formData)
  }

  public async getWeatherAlerts(): Promise<any> {
    const getWeatherAlerts =  this.getFcns().httpsCallable('getWeatherAlerts');
    return getWeatherAlerts()
  }

  public async getAccessRoles(uid: string): Promise<any> {
    return this.getDb().collection("roles")
      .doc(uid)
      .get()
  }

  public async getMyCounties(uid: string) {
    const snapshot = await this.getAccessRoles(uid);
    const data = snapshot.data();
    const countyIds = [];
    for (const [key, value] of Object.entries(data)) {
      countyIds.push(key)
    }
    return this.getDb().collection("counties").where(firebase.firestore.FieldPath.documentId(), 'in', countyIds).get()
  }
}
