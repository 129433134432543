
import { ExcludedDate } from "@/models/excluded";
import {
  IonButton,
  IonContent,
  IonList,
  IonListHeader,
  IonRow,
  IonCol,
  IonGrid,
  popoverController,
} from "@ionic/vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "CalendarDayPopover",
  components: { IonButton, IonContent, IonList, IonListHeader, IonRow, IonCol, IonGrid },
  props: {
    exDate: {
      type: ExcludedDate,
      default: null,
    },
    dateString: {
      type: String,
      default: null,
    },
  },
  methods: {
    confirmed() {
      popoverController.dismiss(this.exDate);
    },
    close() {
      popoverController.dismiss();
    },
  },
});
