<template>
  <img
    v-if="type"
    :src="getIcon"
    :alt="type"
    height="32"
    width="48"
    style="border-radius: 4px"
  >
</template>
<script lang='ts'>

import { defineComponent } from "vue";

export default defineComponent({
  name: 'PaymentCardIcon',
  props: {
    type: {
      type: String,
      default: ''
    }
  },
  // American Express, Diners Club, Discover, JCB, MasterCard, UnionPay, Visa, or Unknown.
  computed: {
    getIcon(): any {
      const myType = this.type.toLowerCase().replace(' ', '')
      switch (myType) {
        case 'americanexpress':
        case 'dinersclub':
        case 'discover':
        case 'jcb':
        case 'mastercard':
        case 'visa':
        case 'unionpay':
        case 'unknown':
          return `https://cycleclub.nyc3.digitaloceanspaces.com/icons/${myType}.png`
        default:
          return `https://cycleclub.nyc3.digitaloceanspaces.com/icons/unknown.png`
      }
    }
  }
});
</script>
