import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
// import Home from './../views/Home.vue';
import Apply from './../views/Apply.vue';
import Permit from './../views/Permit.vue';
import Complete from './../views/Complete.vue';
import Review from './../views/Review.vue';
import CheckExisting from './../views/CheckExisting.vue';
import Login from './../views/Login.vue';
import Dashboard from '../views/dashboard/Dashboard.vue';
import Counties from '../views/dashboard/Counties.vue';
import SearchPermits from '../views/dashboard/SearchPermits.vue';
import Weather from '../views/dashboard/Weather.vue';
import CountyAdmin from '../views/admin/Admin.vue';
import CountyDetails from '../views/admin/CountyDetails.vue';
import CountyCalendar from '../views/admin/CountyCalendar.vue';
import CountyOrdinance from '../views/admin/CountyOrdinance.vue';
import Billing from '../views/settings/Billing.vue';
import Settings from '../views/settings/Settings.vue';
import SettingsTabs from '../views/settings/SettingsTabs.vue';
import OrdinancePreview from '../views/OrdinancePreview.vue';
import Onboard from '../views/Onboard.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Apply
  },
  {
    path: '/permit',
    name: 'Permit',
    component: Permit
  },
  {
    path: '/check',
    name: 'CheckExisting',
    component: CheckExisting
  },
  {
    path: '/review',
    name: 'Review',
    component: Review
  },
  {
    path: '/complete',
    name: 'Complete',
    component: Complete
  },
  {
    path: '/ordinancepreview',
    name: 'OrdinancePreview',
    component: OrdinancePreview
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/onboard',
    name: 'Onboard',
    component: Onboard
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    children: [
      {
        path: '',
        redirect: '/dashboard/permits'
      },
      {
        path: 'permits',
        name: 'SearchPermits',
        component: SearchPermits
      },
      {
        path: 'weather',
        name: 'Weather',
        component: Weather
      }
    ]
  },
  {
    path: '/admin',
    name: 'CountyAdmin',
    component: CountyAdmin,
    children: [
      {
        path: '',
        redirect: '/admin/details'
      },
      {
        path: 'details',
        name: 'CountyDetails',
        component: CountyDetails
      },
      {
        path: 'calendar',
        name: 'Calendar',
        component: CountyCalendar
      },
      {
        path: 'ordinance',
        name: 'Ordinance',
        component: CountyOrdinance
      },
    ]
  },
  {
    path: '/settings',
    name: 'SettingsTabsPage',
    component: SettingsTabs,
    children: [
      {
        path: '',
        redirect: '/settings/billing'
      },
      {
        path: 'profile',
        name: 'Settings',
        component: Settings
      },
      {
        path: 'billing',
        name: 'Billing',
        component: Billing
      },
      {
        path: 'counties',
        name: 'Counties',
        component: Counties
      },
    ]
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
