<template>
  <ion-grid style="min-height: 80vh">
    <ion-row class="ion-justify-items-center ion-padding-horizontal">
      <ion-col size-lg="2" size="auto"> </ion-col>
      <ion-col size-lg="8" class="flex">
        <h1>{{ $t("county.pageTitle") }}</h1>
      </ion-col>
      <ion-col size-lg="2" size="auto"> </ion-col>
    </ion-row>
    <ion-row class="ion-padding-horizontal">
      <ion-col size-lg="2" size="auto"> </ion-col>
      <ion-col size-lg="8" class="flex">
        <h4>
          {{ $t("county.updateDescription") }}
        </h4>
      </ion-col>
      <ion-col size-lg="2" size="auto"> </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size-lg="2"> </ion-col>
      <ion-col size-lg="8" size="12">
        <div class="flex ion-padding-horizontal">
          <div style="margin-left: auto">
            <ion-icon
              button
              @click="edit = !edit"
              slot="end"
              color="secondary"
              size="large "
              class="pointer"
              :icon="edit ? lockClosedOutline : lockOpenOutline"
            ></ion-icon>
          </div>
        </div>
        <ion-list v-if="loaded" class="ion-no-padding">
          <ion-item color="light">
            <ion-label position="floating">{{ $t("county.name") }}</ion-label>
            <ion-input
              :disabled="!edit"
              autocomplete
              v-model="formData.name"
            ></ion-input>
          </ion-item>
          <ion-item color="light">
            <ion-label position="floating">{{
              $t("county.contactFirstName")
            }}</ion-label>
            <ion-input
              :disabled="!edit"
              autocomplete
              v-model="formData.contactFirstName"
            ></ion-input>
          </ion-item>
          <ion-item color="light">
            <ion-label position="floating">{{
              $t("county.contactLastName")
            }}</ion-label>
            <ion-input
              :disabled="!edit"
              autocomplete
              v-model="formData.contactLastName"
            ></ion-input>
          </ion-item>
          <ion-item color="light">
            <ion-label position="floating">{{
              $t("county.contactAddress")
            }}</ion-label>
            <ion-input
              :disabled="!edit"
              autocomplete
              v-model="formData.contactAddress"
            ></ion-input>
          </ion-item>

          <ion-item color="light">
            <ion-label position="floating">{{
              $t("county.contactPhone")
            }}</ion-label>
            <ion-input
              autocomplete
              :disabled="!edit"
              type="tel"
              inputmode="tel"
              v-model="formData.contactPhone"
            ></ion-input>
          </ion-item>

          <ion-item color="light">
            <ion-label position="floating">{{
              $t("county.contactTitle")
            }}</ion-label>
            <ion-input
              autocomplete
              :disabled="!edit"
              type="email"
              inputmode="email"
              v-model="formData.contactTitle"
            ></ion-input>
          </ion-item>

          <ion-item color="light">
            <ion-label position="floating">{{
              $t("county.additionalConditions")
            }}</ion-label>
            <ion-textarea
              maxlength="300"
              :disabled="!edit"
              spellcheck
              v-model="formData.additionalConditions"
              rows="3"
            ></ion-textarea>
          </ion-item>

          <ion-item color="light" lines="none" v-if="errorTxt && !success">
            <ion-text
              :color="success ? 'success' : 'danger'"
              class="ion-text-center w100"
            >
              <h4>
                {{ errorTxt }}
              </h4>
            </ion-text>
          </ion-item>
        </ion-list>
      </ion-col>
      <ion-col size-lg="2"> </ion-col>
    </ion-row>
    <ion-row class="ion-padding-bottom">
      <ion-col size-lg="2"> </ion-col>

      <ion-col size-lg="8">
        <div class="flex w100 pt-4">
          <div style="margin: auto 0 auto auto">
            <ion-button
              v-show="edit"
              :disabled="submitting"
              @click="checkForm"
              class="ion-padding-end"
              >{{ $t("ordinance.saveChanges") }}</ion-button
            >
          </div>
        </div>
      </ion-col>
      <ion-col size-lg="2"> </ion-col>
    </ion-row>
  </ion-grid>
  <ion-footer v-if="submitting">
    <ion-progress-bar type="indeterminate"></ion-progress-bar>
  </ion-footer>
</template>
<script lang='ts'>
import {
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonFooter,
  IonItem,
  IonInput,
  IonTextarea,
  IonLabel,
  IonList,
  IonProgressBar,
  IonRow,
  IonText,
} from "@ionic/vue";
import {
  saveOutline,
  lockOpenOutline,
  lockClosedOutline,
} from "ionicons/icons";
import { useSnackbarPlugin } from "snackbar-vue";
import { defineComponent, reactive, ref } from "vue";

export default defineComponent({
  name: "CountyInfoForm",
  props: {
    initFormData: {
      type: Object,
      default: null,
    },
  },
  components: {
    IonButton,
    IonCol,
    IonGrid,
    IonItem,
    IonIcon,
    IonFooter,
    IonInput,
    IonProgressBar,
    IonTextarea,
    IonLabel,
    IonList,
    IonRow,
    IonText,
  },
  setup(props: any) {
    const snack = useSnackbarPlugin();
    const loaded = ref(false);
    const submitting = ref(false);
    const success = ref(false);
    const errorTxt = ref("");
    const edit = ref(false);
    const formData = reactive(Object.assign({}, props.initFormData));
    let t: any;
    return {
      snack,
      formData,
      t,
      loaded,
      submitting,
      success,
      errorTxt,
      edit,
      saveOutline,
      lockOpenOutline,
      lockClosedOutline,
    };
  },
  computed: {
    selectedCounty(): any {
      return this.$store.state.selectedCounty;
    },
    getSavedFormData(): any {
      return this.$store.state.formData;
    },
  },
  mounted() {
    // we need to wait for the store to get crap outta storage.
    setTimeout(() => {
      this.loaded = true;
    }, 300);
  },
  methods: {
    async checkForm() {
      this.submitting = true;
      const errorMsg = await this.$store.dispatch(
        "updateCounty",
        this.formData
      );
      if (errorMsg && errorMsg.length) {
        this.errorTxt = errorMsg;
        this.success = false;
      } else {
        this.errorTxt = this.$t("county.updateSuccess");
        this.success = true;
      }
      this.submitting = false;
      this.snack.show({
        position: "bottom",
        text: this.errorTxt ? this.errorTxt : this.$t("county.datesAdded"),
        time: 2500,
        close: true,
      });
      setTimeout(() => {
        this.errorTxt = "";
      }, 2500);
    },
  },
  watch: {
    initFormData(val) {
      this.formData = val;
    },
  },
});
</script>
