import { FirestoreDate } from "./permit";
/*
EXCLUDED DATE MODEL
{
    date {seconds , nanoseconds}
    reason "spring burn ban" 
}

*/

export class ExcludedDate {
    constructor(
        public date?: FirestoreDate,
        public reason?: string,
        public id?: string
    ) { }
}

// Firestore data converter
export const excludedConverter = {
    toFirestore: function (exDate: ExcludedDate) {
        return {
            date: exDate.date,
            reason: exDate.reason,
            id: exDate.id
        };
    },
    // firebase.firestore.QuerySnapshot<ExcludedDate>
    fromFirestore: function (snapshot: any, options: any) {
        const data = snapshot.data(options);
        return new ExcludedDate(
            data.date,
            data.reason,
            data.id
        );
    }
};