
import {
  IonButtons,
  IonCol,
  IonRow,
  IonInput,
  IonContent,
  IonHeader,
  IonGrid,
  IonList,
  IonIcon,
  IonItem,
  IonLabel,
  IonTitle,
  IonToolbar,
  modalController,
} from "@ionic/vue";
import { defineComponent } from "vue";
import { County } from "@/models/county";
import { closeOutline } from "ionicons/icons";
import InputError from "./../permit/InputError.vue";
import { useForm, useField } from "vee-validate";
import { isRequired } from "@/utils/form.validators";
import StripeBox from "./../onboard/StripeBox.vue";
import { useSnackbarPlugin } from "snackbar-vue";

export default defineComponent({
  name: "UpdateBillingModal",
  components: {
    IonButtons,
    IonCol,
    IonRow,
    IonInput,
    IonContent,
    IonHeader,
    IonGrid,
    IonIcon,
    IonList,
    IonItem,
    IonLabel,
    IonTitle,
    IonToolbar,
    StripeBox,
    InputError,
  },
  setup() {
    const { handleSubmit } = useForm();
    const onSubmit = handleSubmit(() => {
      //
    });
    const snack = useSnackbarPlugin();

    const { value: nameOnCard, errorMessage: nameOnCardError } = useField(
      "nameOnCard",
      isRequired
    );
    const { value: address, errorMessage: addressError } = useField(
      "address",
      isRequired
    );
    const { value: paymentMethod, errorMessage: paymentMethodError } = useField(
      "paymentMethod",
      isRequired
    );

    return {
      nameOnCard,
      address,
      paymentMethod,
      addressError,
      nameOnCardError,
      paymentMethodError,
      onSubmit,
      snack,
      closeOutline,
    };
  },
  methods: {
    closeMe(c: any) {
      modalController.dismiss(c);
    },
    async updatePayment(paymentMethod: any) {
      // submit the form if we have everything ,
      if (!paymentMethod || !this.address || !this.nameOnCard) {
        this.snack.show({
          position: "bottom",
          text: "Please check submission.",
          time: 2500,
          close: true,
        });
        return;
      }
      // show snack saying submitting..
      this.snack.show({
        position: "bottom",
        text: "Submitting..",
        time: 2500,
        close: true,
      });

      // do something...
      const errorMsg = await this.$store.dispatch("updateCustomer", {
        nameOnCard: this.nameOnCard,
        address: this.address,
        paymentMethod: paymentMethod,
      });

      if (errorMsg) {
        this.snack.show({
          position: "bottom",
          text: errorMsg,
          time: 2500,
          close: true,
        });
        return;
      }

      await this.$store.dispatch("loadCustomer");

      this.snack.show({
        position: "bottom",
        text: "Success. Payment Information Updated.",
        time: 2500,
        close: true,
      });
      this.closeMe(null);
    },
  },
});
