
import {
  IonButton,
  IonIcon,
  IonText,
  menuController,
} from "@ionic/vue";
import { menuOutline } from "ionicons/icons";
import { defineComponent } from "vue";

export default defineComponent({
  name: "LogoHeader",
  components: {
    IonButton,
    IonIcon,
    IonText,
  },
  setup() {
    return { menuOutline };
  },
  computed: {
    getUser(): any {
      return this.$store.state.myUser;
    },
    getUsername(): any {
      const a = this.getUser ? this.getUser.email.split("@") : [""];
      return a[0];
    },
    scrollTop() {
      return this.$store.state.scrollTop;
    },
  },
  methods: {
    openMenu() {
      menuController.enable(true, "first");
      menuController.open("first");
    },
  },
  mounted() {
    this.$store.dispatch("setScroll", 0)
  }
});
