<template>
  <ion-header>
    <ion-toolbar  color="secondary" class="pt-2 pb-2" >
      <ion-title>{{ $t("review.selectPermitType") }}</ion-title>
      <ion-buttons slot="end">
        <ion-icon
          @click="closeMe(null)"
          class="pr-2 pointer"
          button
          :icon="closeOutline"
          size="large"
        />
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding-end">
    <div class="ion-padding">
      <div class="ion-padding-horizontal" >
        <h5 class="ion-text-start">
        {{$t("apply.permitTypesDesc")}}
        </h5>
      </div>
    </div> 
    <ion-list class="ion-padding">
      <ion-item button @click="closeMe('Daily')" class="pointer">
        <ion-label>
          <h2>{{$t("permit.permitDaily")}}</h2>
        </ion-label>
      </ion-item>
      <ion-item button @click="closeMe('ACD')" class="pointer">
        <ion-label>
          <h2>{{$t("permit.permitACD")}}</h2>
        </ion-label>
      </ion-item>
    </ion-list>
  </ion-content>
</template>

<script lang='ts'>
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonList,
  IonIcon,
  IonItem,
  IonLabel,
  IonToolbar,
  modalController
} from "@ionic/vue";
import { defineComponent } from "vue";
import { closeOutline } from "ionicons/icons";

export default defineComponent({
  name: "Modal2",
  setup() {
    return { closeOutline };
  },
  components: {
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonList,
    IonItem,
    IonLabel,
    IonToolbar
  },
  methods: {
    closeMe(c: string) {
      modalController.dismiss(c)
    }
  }
});
</script>