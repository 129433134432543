
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonList,
  IonText,
  IonTitle,
  IonToolbar,
  modalController,
} from "@ionic/vue";
import { defineComponent } from "vue";
import { closeOutline, downloadOutline } from "ionicons/icons";
import { FirePermit } from "@/models/permit";
import ItemRow from "./ItemRow.vue";
import { dateMixin } from "@/utils/date.format";
import { downloadCSV } from "@/utils/json2csv";

export default defineComponent({
  mixins: [dateMixin],
  name: "PermitModal",
  setup() {
    return { closeOutline, downloadOutline };
  },
  props: {
    permit: {
      type: FirePermit,
      default: null,
    },
  },
  components: {
    IonButtons,
    IonContent,
    IonHeader,
    IonList,
    IonIcon,
    IonText,
    IonTitle,
    IonToolbar,
    ItemRow,
  },

  methods: {
    closeMe() {
      modalController.dismiss();
    },
    async download() {
      const element = document.createElement("a");
      element.setAttribute(
        "href",
        "data:text/plain;charset=utf-8," +
          encodeURIComponent(await downloadCSV([this.permit]))
      );
      element.setAttribute("download", `${this.permit.permitId}_permit.csv`);
      element.style.display = "none";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    },
  },
});
